.serviceInfo a {
    background-color: #208775;
    color: #fff;
    font-weight: 600;
    padding: 14px 40px;
    border: 1px solid #208775;
    border-radius: 4px;
    margin-right: 2%;
    margin-top: 2%;
}

.container {
    font: Source Sans Pro;
    text-align: center;
}

.container h2 {
    font-size: x-large;
    margin-top: 5%;
    margin-bottom: 5%;
}

.container h3 {
    font-size: x-large;
    margin-top: 3%;
    margin-bottom: 2%;
}
