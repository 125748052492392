@media screen and (max-device-width: 485px) {
.upBar {
  color: whitesmoke;
  background-color: teal;
  height: auto;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  vertical-align: middle;
}
.upBar button{
  color:#FFFFFF!important;
  text-shadow: none!important;
  font-size: 2rem;
  opacity:0.8!important;
}

.upBar button:hover{
  opacity:0.5!important;
}
.upBar button:focus{
  outline: none;
}
.closeIcon {
  margin-right: 20px;
}
.shareContainer{
  height: 60px;
}
.modalInput {
  color: #666;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  height: 100%;
  padding: 5px 10px;
  font-weight: 500;
}
.modalInput2{
  color: #666;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  border: none;
  border-radius: 10px;
  width: 60%;
  font-size: 15px;
  height: 100%;
  padding: 5px 10px;
  font-weight: 500;
}
.modalLogo {
  margin-top:5px;
  margin-right: 10px;
}
.modalButton {
  color:white;
  border-radius: 10px;
  background-color: #208675;
  margin-left: 15px;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  padding: 5px 10px;
}
.modalButtonCopied {
  color:#208675;
  border-radius: 10px;
  border:none;
  margin-left: 15px;
  padding: 5px 10px;
}
.linkContainer{
  display: flex;
}
}
@media screen and (max-device-width: 285px) {
  .upBar {
    color: whitesmoke;
    background-color: teal;
    height: auto;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
  }
  .upBar button{
    color:#FFFFFF!important;
    font-size: 2rem;
    text-shadow: none!important;
    opacity:0.8!important;
  }
  .upBar button:hover{
    opacity:0.5!important;
  }
  .upBar button:focus{
    outline: none;
  }
  .closeIcon {
    margin-right: 20px;
  }
  .shareContainer{
    height: 60px;
  }
  .modalInput {
    color: #666;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 18px;
    height: 100%;
    padding: 5px 10px;
    font-weight: 500;
  }
  .modalInput2{
    color: #666;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    border: none;
    border-radius: 10px;
    width: 60%;
    font-size: 15px;
    height: 100%;
    padding: 5px 10px;
    font-weight: 500;
  }
  .modalLogo {
    margin-top:5px;
    margin-right: 2px;
  }
  .modalButton {
    color:white;
    border-radius: 10px;
    background-color: #208675;
    margin-left: 15px;
    border: none;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    padding: 5px 10px;
  }
  .modalButtonCopied {
    color:#208675;
    border-radius: 10px;
    border:none;
    margin-left: 15px;
    padding: 5px 10px;
  }
  .linkContainer{
    display: flex;
  }
  }
@media screen and (min-width: 480px){
  .upBar {
    color: whitesmoke;
    background-color: teal;
    height: 60px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
  }
  .upBar button{
    color:#FFFFFF!important;
    font-size: 2rem;
    text-shadow: none!important;
    opacity:0.8!important;
  }
  .upBar button:hover{
    opacity:0.5!important;
  }
  .upBar button:focus{
    outline: none;
  }
  .closeIcon {
    margin-right: 20px;
  }
  
  .modalInput {
    color: #666;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 18px;
    height: 100%;
    padding: 5px 10px;
    font-weight: 500;
  }
  .modalInput2{
    color: #666;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    border: none;
    border-radius: 10px;
    width: 80%;
    font-size: 18px;
    height: 100%;
    padding: 5px 10px;
    font-weight: 500;
  }
  .modalLogo {
    margin-top:5px;
    margin-right: 20px;
  }
  .modalButton {
    color:white;
    border-radius: 10px;
    background-color: #208675;
    margin-left: 15px;
    border: none;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    padding: 5px 10px;
  }
  .modalButtonCopied {
    color:#208675;
    border-radius: 10px;
    border:none;
    margin-left: 15px;
    padding: 5px 10px;
  }
}
@media screen and (min-width: 994px){
.upBar {
  color: whitesmoke;
  background-color: teal;
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  vertical-align: middle;
}
.upBar button{
  color:#FFFFFF!important;
  font-size: 2rem;
  text-shadow: none!important;
  opacity:0.8!important;
}
.upBar button:hover{
  opacity:0.5!important;
}
.upBar button:focus{
  outline: none;
}
.closeIcon {
  margin-right: 20px;
}

.modalInput {
  color: #666;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  height: 100%;
  padding: 5px 10px;
  font-weight: 500;
}
.modalInput2{
  color: #666;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  border: none;
  border-radius: 10px;
  width: 88%;
  font-size: 18px;
  height: 100%;
  padding: 5px 10px;
  font-weight: 500;
}
.modalLogo {
  margin-top:5px;
  margin-right: 20px;
}
.modalButton {
  color:white;
  border-radius: 10px;
  background-color: #208675;
  margin-left: 15px;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  padding: 5px 10px;
}
.modalButtonCopied {
  color:#208675;
  border-radius: 10px;
  border:none;
  margin-left: 15px;
  padding: 5px 10px;
}
}