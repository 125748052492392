.AppTimelineList {
  background-color: rgb(255, 255, 255);
  padding: 30px 20px 20px;
}

.headerCategories {
  background-color: white;
  width: 100%;
  z-index: 1;
}
.carousel-control-next-ico {
  width: 20px !important;
  height: 20px !important;
}
.previous {
  height: 30px;
  width: 30px;
  transform: rotate(180deg);
}
.next {
  height: 30px;
  width: 30px;
}
.AppTimelineList > div > ol {
  bottom: -50px;
}
.AppTimelineList > div > ol > li {
  background-color: #208775;
}
.AppTimelineList > div > a {
  filter: brightness(0);
  width: 10%;
}
.AppTimelineList > div > a:active {
  filter: brightness(100%);
}
.AppTimelineList > div > div > div > div > div {
  margin-right: 5%;
  margin-left: 5%;
}

@media screen and (min-width: 1000px) {
  .headerCategories {
    position: fixed;
  }
  .listTimelines {
    padding-top: 150px;
  }
}
@media screen and (max-width: 866px) {
  .AppTimelineList {
    padding: 30px 20px 20px;
  }
}

.container {
  max-width: 95%;
}

.myCol {
  padding: 15px;
}

.title {
  color: #333;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 1.1;
  font-size: 40px;
}

.exploreCategories {
  display: flex;
  background-color: #e7eceb;
}

.category {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #67706f;
  text-decoration: none;
  cursor: pointer;
}

.selected {
  color: white;
  text-decoration: none;
  background-color: #208775;
}

@media (max-width: 1300px) {
  .category {
    width: 25%;
  }
  .exploreCategories {
    display: flex;
    background-color: #e7eceb;
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .category {
    width: 33%;
  }
}

@media (max-width: 800px) {
  .category {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .category {
    width: 100%;
  }
}

.category:hover {
  color: white;
  text-decoration: none;
  background-color: #208775;
}

.searchText {
  font-size: 17px;
  font-weight: 500;
}

.loaderContainer {
  margin-top: 15%;
}

.quote {
  color: #208775;
  font-style: italic;
  margin-top: 30px;
}

.quoteAuthor {
  margin-left: 35%;
}

.createTimelineContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.setFictionsButtonSelected {
  position: relative;
  color: white;
  background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
  linear-gradient(0deg, #98431B, #98431B);  font-size: 20px;
  width: 90px;
  width: 100%;
  border: none;
  height: 90%;
}

.setFictionsButton {
  position: relative;
  background-color: #c4c4c4;
  border: none;
  color: #727978;
  font-size: 20px;
  width: 100%;
  height: 90%;
}

.setFictionsButton:hover {
  outline: none;
  text-decoration: none;
  color: white;
  background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
  linear-gradient(0deg, #98431B, #98431B);}

.setTimelineButton {
  position: relative;
  background-color: #c4c4c4;
  color: #727978;
  font-size: 20px;
  width: 100%;
  border: none;
  height: 90%;
}
.setTimelineButtonSelected {
  position: relative;
  background-color: #428576;
  color: #FFFFFF;
  font-size: 20px;
  border: none;
  width: 100%;
  height: 90%;
}



.setTimelineButton:hover {
  outline: none;
  text-decoration: none;
  background-color: #428576;
  color: #FFFFFF;
}

.exploreButton{
  margin-top: 18px;
  margin-bottom: 30px;
  margin-left: 15px;
  width: 190px;
  height: 55px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
  cursor: pointer;
}


.rowTitleButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
}