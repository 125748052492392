.backgroundImage {
    position: relative;
    color:#C3C9D1;
    font-size: 18px;
    font-weight: 700;
    height: 53px;
    line-height: 26px;
    text-align: left;
}

.table {
    position: relative;
    width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.colonne {
    position: relative;
    width: 45%;
    text-align:center;
}

.colonne2 {
    position: relative;
    margin-top:auto;
    margin-bottom:auto;
    width: 10%;
    text-align: center;
}

.buttonDiv {
    position:relative;
    position: relative;
    overflow: hidden;
    border:none;
    width:100%;
}

.buttonDiv button {
    position: relative;
    width: 100%;
    border:none;
}

.buttonDiv input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 90px;
    border:none;
}

.buttonUpload {
    background-color: #208775;
    color: #e2e4e5;
    border-radius: 10px;
    font-size: 18px;
    padding:10px 25px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    outline:none;
}

.buttonUpload:hover {
    background-color: #196e60;
    color: #e2e4e5;
    border-radius: 10px;
    font-size: 18px;
    padding:10px 25px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    border:none;
}

.buttonUpload:focus { outline: none; }

.image {
    position: relative;
    height: auto;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content:center;
}

.imageSearched {
    position: absolute;
    width: 100%;
    margin:auto;
    z-index: 0;
}

.deleteImageChoice {
    position: absolute;
    border: none;
    background-color: #208775;
    color: white;
    z-index: 1;
    margin-top:-5px;
    margin-right:-5px;
    right:0;
    width:20px;
    height:20px;
    border-radius: 30px;
    border:none;
}

.buttonGenerate{
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    color: white;
    border-radius: 10px;
    font-size: 18px;
    padding:10px 25px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    outline:none;
    opacity: 1;
    cursor: pointer;
}

.buttonGenerate:hover {
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    color: #e2e4e5;
    border-radius: 10px;
    font-size: 18px;
    padding:10px 25px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    border:none;
    opacity: 0.80;
}

.buttonGenerate:focus { outline: none; }


.wandlogo{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}