.selected {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  opacity: 1;
  transform: translateX(0);
  transition: 0.5s; //ease-in-out;
  z-index: 99;
}
.cropper2 {
  cursor: none;
  pointer-events: none;
}

.hidden {
  opacity: 0;
}

.right {
  transform: translateX(-100%);
}

.left {
  transform: translateX(100%);
}

.up {
  transform: translate(-100%);
}

.down {
  transform: translateX(100%);
}

.boxtimeline {
  position: relative;
  overflow: auto;
  background-color: whitesmoke;
  height: 57%;
  z-index: -1;
  padding: 15px;
}

.boxtimelineTitle {
  font-size: 25px;
  text-align: end;
}

.boxtimelineDescription {
  font-size: 17px;
  text-align: justify;
}

.pImg {
  height: 35%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
  display: flex;
  position: relative;
}

.pImg3 {
  height: 35%;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: #32c7ae;
}
.pImg2 {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  z-index: 2;
  background-color: #32c7ae;
}

.imgWhiteBackground {
  height: 90%;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-color: whitesmoke;
}

@media (max-width: 731px) {
  .boxtimeline {
    width: 100%;
  }

  .selected {
    width: 99%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .boxtimeline {
    width: 100%;
  }

  .boxtimelineTitle {
    font-size: 16px;
  }

  .boxtimelineDescription {
    font-size: 13px;
    text-align: left;
  }

  .event {
    width: 75%;
  }

  .pImg {
    width: 100%;
  }
  .pImg2 {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .boxtimeline {
    width: 100%;
  }

  .event {
    width: 90%;
  }
}

.dateEstory {
  position: absolute;
  height: auto;
  width: auto;
  color: white;
  background-color: #208775;
  z-index: 4;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 6px;

  &Generated {
    font-size: 1.5em;
    margin: 0;
    line-height: 1.2;
    margin-bottom: 8px;
  }
}
