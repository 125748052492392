.imgLoading {
  width: 100px;
  background-color: rgba(0, 0, 0, 0.089);
  padding: 3px;
  border-radius: 10px;
}

/* Pulse */
@-webkit-keyframes pulse {
  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes pulse {
  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.container {
  align-items: center;
  align-content: center;
  text-align: center;
  font: Source Sans Pro;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-image: url("../../assets/img/about-bg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.container h1 {
  color: whitesmoke;
  margin-top: 10%;
  margin-bottom: 5%;
  font-size: 3rem;
}

.log {
  margin: auto;
  margin-top: 12%;
  width: 25%;
  height: 13%;
  background-color: rgb(19, 35, 47, 0.5);
  padding: 30px;
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 10px 4px rgb(19 35 47 / 30%);
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loadingContent {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
