.card{
  background: rgba(196, 196, 196, 0.15);
  height: 550px;
  width: 90%;
  margin: auto;
}

.cardPrice{
  text-align: start;
  font-weight: bold;
}

.cardTitle {
  color: #208775;
  font-weight: bold;
  font-size: 30px;
  text-align: start;
  line-height: 30px;
}

.cardHeader{
  padding: 10% 0 0 13%;
}

.cardContent{
  padding: 2% 0 0 13%;
  height: 48%;
  margin-bottom: 12%;
}

.cardSubtitle{
  text-align: start;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #081A27;
}

.cardFirstDescription{
  text-align: start;
  font-weight: 700;
  font-size: 20px;
  line-height: 42px;
}

.cardDescription {
  text-align: start;
  font-size: 20px;
  line-height: 42px;
}

.cardGetButton {
  position: relative;
  width: 40%;
  color: #fff;
  border-radius: 10px;
  background-color: #208775;
  font-weight: bold;
  fontSize: 20px;
  padding: 3%;
}

.cardGetButton:hover {
  border-radius: 10px;
  background: #208775;
  filter: brightness(80%);
  color: #fff;
  fill: solid;
  font-weight: bold;
  padding: 3%;
  text-decoration: none;
  cursor: pointer;
}

.cancelSubButton {
  position: relative;
  width: 40%;
  color: #208775;
  fill: solid;
  font-weight: bold;
  font-size: large;
  margin-top: 1%;
  padding: 3%;
}

.cancelSubButton:hover {
  width: 40%;
  filter: brightness(80%);
  color: #208775;
  fill: solid;
  font-weight: bold;
  font-size: large;
  margin-top: 1%;
  margin-bottom: 40px;
  text-decoration: none;
  cursor: pointer;
}

.inProgress {
  border-top: 90px solid #208775;
  border-right: 90px solid transparent;
  position: absolute;
  z-index: 1;
  padding: -40px;
}

.inProgress span {
  position: absolute;
  top: -60px;
  left: -9px;
  text-align: center;
  transform: rotate(-45deg);
  display: block;
  color: white;
  font-weight: 400;
  font-size: small;
}

.textRenew{
  margin-left: 20px;
  font-weight: bold;
}


@media screen and (max-device-width: 1145px) {

}
@media screen and (max-device-width: 780px) {

}