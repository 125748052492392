.title {
    color: #208775;
    font-weight: bold;
    margin-top: 2%;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    text-align: center;
}
.subtitle{
    font-size: 20px;
    line-height: 25px;
    margin-top: 1%;
}
.logos{
    width: 23%;
}


@media screen and (max-device-width: 780px) {
    .logos{
        width: 50%;
    }
}
.myCol {
    padding: 15px;
}