.modalHeader {
    color: whitesmoke;
    background-color: teal;
    height: 60px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
}
.buttonDiv {
    position:relative;
    overflow: hidden;
    border:none;
    width:60%;
}
.buttonDivCenter {
    position:relative;
    overflow: hidden;
    border:none;
    width:100%;
}

.buttonGenerate{
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    color: white;
    border-radius: 10px;
    font-size: 18px;
    align-items: center;
    display:flex;
    justify-content: center;
    outline:none;
    border: none;
    width: 90%;
    height: 85%;
    margin-left: 5%;
    font-weight: bold;
    cursor: pointer;
}
.buttonGenerate:disabled{
    opacity: 0.7;
}

.buttonGenerateCenter{
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    color: white;
    border-radius: 10px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    outline:none;
    border: none;
    width: 20%;
    height: 25%;
    font-weight: bold;
    cursor: pointer;

}
.buttonGenerateCenter:disabled{
    opacity: 0.7;
    cursor: default;
}
.wandlogo{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.imageCreator{
    display: flex;
    flex-direction: column;
}
.imageCreatorResult input-group {
    display: flex;
    flex-direction: column;
    width: auto;
}
.label {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 3px;
    color: #208775;
}

.labelCenter{
    font-size: 16px;
    line-height: 23px;
    color: #208775;
    text-align: center;
}
.description{
    margin-bottom: 3%;
    width: 100%;
}
.inputDescription{
    width: 100%;
}
.groupButtonInput{
    display: flex;
    flex-direction: row;
}

.descriptionResult{
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1.5%;
}
.styleImage{
    margin-top: 1%;
}

.colonne {
    position: relative;
    width: 100%;
    text-align:center;
}

.colonneCenter {
    position: relative;
    width: 100%;
    text-align:center;
}

.image{
    width: 80%;
}

.imageSelected{
    width: 80%;
    border: 5px solid #508676;
}

.selected {
    border-top: 80px solid #208775;
    border-right: 80px solid transparent;
    position: absolute;
    z-index: 1;
    margin-left: 10%;
}
.noSelected{
    display: none;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height : 400px;
}
.loaderContent img {
    height : 100px !important;
    width : 100px !important;
}

.bottomOther{
    display: flex;
    flex-direction: row;
}
.modalHeader button {
    color:#FFFFFF!important;
    font-size: 2rem;
    text-shadow: none!important;
    opacity:0.8!important;
}
.modalHeader button:hover{
    opacity:0.5!important;
}

.modalHeader button:focus {
    outline: none;
}

.searchButton {
    color: gainsboro;
    width: 100%;
    height: 60%;
}

.otherInput{
    display: flex;
    flex-direction: column;
}

.searchButton:focus {
    outline: none;
}

.modal {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    width:150%;
}

.viewImages {
    max-height: 400px;
    width: 100%;
    overflow-y: scroll;
}

.searched {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    margin-bottom: 20px;
}

.imageSearched {
    margin: auto;
}

.inputOtherForm{
    display: flex;
    flex-direction: column ;
}
.back {
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    color: #1F1F1F;
}
.arrow{
    width: 33px;
    height: 33px;
}

.buttonSearched {
    width: 55%;
    margin: auto;
    height: 14%;
    font-size: 17px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    color: #f3f3f3;
    background-color: #4d9f91;
    align-content: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

.buttonSearched:focus {
    outline: none;
}
@media screen and (max-width:  634px) {
    .viewImages {
        max-height: 200px;
    }
}

@media screen and (max-width:  780px) {
    .buttonGenerate{
        margin-left: 0%;
    }
    .buttonGenerateCenter{
        width: 80%;
    }
    .descriptionResult{
        width: 100%;
    }
    .inputDescriptionResult{
        margin-bottom: 10px;
    }
    .groupButtonInput{
        flex-direction: column;
        width: 100%;
    }
    .imageCreatorResult {
        display: block;
        font-size: 10px;
    }
}
.styleChoice{
    cursor: pointer;
}