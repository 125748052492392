.footerLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  color: #208775;
}

.footerSocialNetwork {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.footerSocialIcon {
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
  color: #208775;
}

.footerSocialIcon:hover {
  color: #124e44;
}

.footerLink {
  color: #208775;
}

.footerLink:hover {
  color: #13554a;
  text-decoration: none;
}
