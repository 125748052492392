.eventAddContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.AdButtonSelected {
  position: relative;
  background-color: #208775;
  width: 50%;
  height: 40px;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  font-size: large;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.BceButtonSelected {
  position: relative;
  background-color: #208775;
  width: 50%;
  height: 40px;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  font-size: large;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.AdButton {
  position: relative;
  background-color: grey;
  width: 50%;
  height: 40px;
  border: none;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 0.4;
  font-size: large;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.BceButton {
  position: relative;
  background-color: grey;
  width: 50%;
  height: 40px;
  border: none;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 0.4;
  font-size: large;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.AdButton:hover {
  background-color: #208775;
}
.BceButton:hover {
  background-color: #208775;
}
.cardContainer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0px 10px 10px 10px;
  background-color: rgb(0, 5, 22, 0.9);
  border-radius: 10px;
  margin-bottom: 5px;
}

.card {
  position: relative;
  height: 98%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  vertical-align: center;
  margin: auto;
}

.buttonAddEvent {
  position: relative;
  height: 40px;
  width: 100%;
  margin: auto;
}

.buttonAddToPreview {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #98431b;
  color: aliceblue;
  font: Source Sans Pro;
  font-weight: 500;
}

.buttonAddToPreview:disabled {
  opacity: 0.6;
}

.modifyEventInPreview {
  position: relative;
  height: 100%;
  width: 90%;
  margin-top: 2%;
  margin-right: auto;
  margin-left: auto;
  border: none;
  display: flex;
  flex-direction: row;
}

.buttonCancel {
  position: relative;
  height: 100%;
  width: 48%;
  border: solid rgb(219, 219, 219) 2px;
  border-radius: 5px;
  background-color: transparent;
  color: aliceblue;
  font: Source Sans Pro;
  font-weight: 500;
}

.buttonModifyEvent {
  position: relative;
  height: 100%;
  width: 48%;
  border: none;
  border-radius: 5px;
  background-color: #208775;
  color: aliceblue;
  font: Source Sans Pro;
  font-weight: 500;
  margin-left: auto;
}

.buttonModifyEvent:disabled {
  opacity: 0.6;
}

.card::-webkit-scrollbar {
  width: 4px;
}

.card::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.card::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}

.card::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.date {
  position: relative;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
}

.dateFieldContainer {
  width: 45%;
  height: 40px;
  margin-top: 10%;
  margin-right: 4px;
}
.monthFieldContainer {
  position: relative;
  width: 31%;
  height: 40px;
  margin-top: 10%;
  margin-right: 4px;
}
.dayFieldContainer {
  position: relative;
  height: 40px;
  width: 25%;
  margin-top: 10%;
  margin-right: 4px;
}

.field2 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #c4c4c4;
  border: 1px #c4c4c4 solid;
  border-radius: 5px;
  padding: 10px;
  font: Source Sans Pro;
  font-weight: 300;
}
.field4 {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: transparent;
  color: #c4c4c4;
  border: 1px #c4c4c4 solid;
  border-radius: 5px;
  padding: 10px;
  font: Source Sans Pro;
  font-weight: 300;
}

.field3 {
  position: relative;
  width: 98%;
  height: 100%;
  background-color: transparent;
  color: #c4c4c4;
  border: 1px #c4c4c4 solid;
  border-radius: 5px;
  padding: 10px;
  font: Source Sans Pro;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 14px) calc(1em + 1px), calc(100% - 9px) calc(1em + 1px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.options {
  background-color: #464747;
  color: rgb(219, 219, 219);
  font-weight: 500;
}

.options:hover {
  box-shadow: 0 0 3pt 2pt #9da0a3;
  background-color: #5b5c5c;
}

.descriptionEventArea {
  position: relative;
  width: 95%;
  height: 100px;
  min-height: 78px;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
}

.eventTitle {
  position: relative;
  width: 95%;
  height: 40px;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
}

.label {
  position: absolute;
  text-align: left;
  top: 0px;
  width: 100%;
  right: 7px;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  color: rgb(219, 219, 219);
  font: Source Sans Pro;
  font-weight: 600;
}

.label3 {
  position: absolute;
  text-align: left;
  top: 0px;
  width: 100%;
  right: 7px;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  color: rgb(219, 219, 219);
  font: Source Sans Pro;
  font-weight: 600;
}

.label3:after {
  content: ' *';
  color: #208775;
}
.label2 {
  position: absolute;
  text-align: left;
  top: 0px;
  width: 100%;
  right: 7px;
  padding: 7px 13px 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  color: rgb(219, 219, 219);
  font: Source Sans Pro;
  font-weight: 600;
}

.label:after {
  content: ' *';
  color: #208775;
}

.field2:focus + .label,
.field2:valid + .label {
  font-size: 100%;
  transform: translate3d(0, -100%, 0);
  opacity: 0.5;
  color: rgb(219, 219, 219);
}

.field2:focus + .label2,
.field2:valid + .label2,
.field3:valid + .label2,
.field3:focus + .label2 {
  font-size: 100%;
  transform: translate3d(0, -100%, 0);
  opacity: 0.5;
  color: rgb(219, 219, 219);
}

.imageEventChoice {
  position: relative;
  width: 95%;
  height: 100px;
  min-height: 70px;
  margin: auto;
}
@media screen and (max-width: 320px){
  .label3{
    top:-9px;
  }
  .BceButton{
    font-size: small;
    padding-left:3px;
  }
  .BceButtonSelected {
    font-size: small;
    padding-left:3px;
  }
}
@media screen and (max-width: 500px) {
  .date {
    position: relative;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .AdButtonSelected {
    font-size: small;
  }
  .BceButtonSelected {
    font-size: small;
  }
  
  .AdButton {
    font-size: small;
  }
  .BceButton {
    font-size: small;
  }
}
