.indicator {
    background-color: rgb(248, 248, 248);
}

.indicator:hover {
    background-color: #208775;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}
