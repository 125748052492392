@media screen and (max-width: 866px) {
  .aboutContainer {
    padding: 35px 10px 20px;
  }
}

@media screen and (min-width: 866px) {
  .aboutContainer {
    padding: 20px 50px 20px;
  }
}

.aboutContainer {
  height: 100%;
  margin: 0 20% 0;
  display: grid;
}

@media (max-width: 1270px) {
  .aboutContainer {
    margin: 0 10% 0;
  }
}

.heading {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

.headingText {
  color: #333;
  font-family: sans-serif;
  font-style: italic;
  font-size: 34px;
  font-weight: lighter;
  line-height: 1.1;
  box-sizing: border-box;
}

.ourTeam {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 30px;
}

.ourTeamText {
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-size: 36px;
  font-weight: lighter;
  line-height: 1.1;
  grid-column: span 3;
  padding-bottom: 50px;
}

.people {
  grid-column-start: span 1;
}

@media (max-width: 1270px) {
  .people {
    grid-column-start: span 3;
  }
}

.what {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.whatTitle {
  grid-column: span 10;
  color: #333;
  font-size: 36px;
  margin-bottom: 20px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.whatDesc {
  font-size: 16px;
  line-height: 28px;
  grid-column-start: 3;
  grid-column-end: 9;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 40px;
  column-gap: 30px;
}

.card {
  grid-column-end: span 1;
}

@media (max-width: 850px) {
  .card {
    grid-column-start: span 3;
  }
}

.once {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 400px 80px;
  column-gap: 50px;
}

@media (max-width: 1600px) {
  .once {
    padding: 80px 300px 80px;
  }
}

@media (max-width: 1200px) {
  .once {
    padding: 80px 100px 80px;
  }
}

@media (max-width: 800px) {
  .once {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
}

.onceText {
  font-size: 36px;
  text-align: start;
}

.onceTextTitle {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.onceTextTitle > h2 {
  font-size: 40px;
}

.onceTextDesc {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 8px;
}

.oncecharts {
  display: grid;
  text-align: start;
}

.onceChartQuote {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: lighter;
  line-height: 1.4;
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 10px;
}

.onceChartQuoteAuthor {
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.2;
}

.progressBar {
  background-color: #011222 !important;
  text-align: right !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-right: 12px !important;
  font-size: 12px !important;
  height: 20px;
  font-weight: 600 !important;
}
.progressBar88 {
  animation: progressBar88 3s forwards;
}
.progressBar95 {
  animation: progressBar95 3s forwards;
}
.progressBar34 {
  animation: progressBar34 3s forwards;
}

.progress {
  height: 20px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-bottom: 25px;
}

@keyframes progressBar88 {
  0% {
    width: 0;
  }
  100% {
    width: 88%;
  }
}
@keyframes progressBar95 {
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}
@keyframes progressBar34 {
  0% {
    width: 0;
  }
  100% {
    width: 34%;
  }
}
