.input_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%), linear-gradient(0deg, #98431B, #98431B);
  padding: 15px;
  border-radius: 20px;
  position: fixed;
  box-sizing: border-box;
  width: 85%;;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.input_container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  filter: blur(15px);
  border-radius: 20px;
  background-color: #5923e292;
  background-image: linear-gradient(264.81deg, #290b77 -11.2%, rgba(88, 35, 226, 0) 125.09%), linear-gradient(0deg, #98431B, #98431B);
}

.input_label {
  display: block;
  margin-left: 0.4em;
  color: #ffffff;
  font-size: 2vw;
  font-weight: bold;
  text-align: left;
}

.input {
  border: none;
  max-height: 75%;
  outline: none;
  width: 100%;
  padding: 0.6em;
  padding-left: 0.9em;
  border-radius: 20px;
  background: #ffffff;
  transition: background 300ms, color 300ms;
  color: black
}

.input:hover,.input:focus {
  color: #000000;
}

.input_generate{
  display:flex;
  flex-direction: row;
}

.button_generate {
  max-height: 75%;
  margin-left: 0.3em;
  align-items: center;
  background-image: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%), linear-gradient(0deg, #98431B, #98431B);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all .3s;
}
 
.button_generate:active,.button_generate:hover {
  outline: 0;
}
  
.button_generate:active {
  transform: scale(0.9);
}

.button_generate:hover,.button_generate:focus {
  background-position: right top;
}

.wandlogo{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.cost{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  align-content: center;
  width: 8%;
  max-height: 75%;
  color: rgb(255, 255, 255);
}