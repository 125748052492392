/* Pulse */
@-webkit-keyframes pulse {
    25% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    75% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}
@keyframes pulse {
    25% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    75% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}
.pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.loadingContainer {
    width: 40%;
    height: 35%;
    position: absolute;
    top: 32.5%;
    left: 30%;
    background-color: rgb(19, 35, 47);
    border-radius: 15px;
}

.loadingContent {
    border-radius: 5px;
}

.container h1 {
    padding: 5% 5% 0% 5%;
    color: whitesmoke;
    font-size: 1.5vw;
}

.container h2 {
    padding: 0% 5% 0% 5%;
    color: whitesmoke;
    margin-top: 3%;
    font-size: 1.25vw;
}

.imgLoading {
    padding: 0% 5%;
    width: 20%;
    background-color: rgb(19, 35, 47);
    border-radius: 10px;
}

.quoteContainer{
    display:flex;
    flex-direction: row;
    color: whitesmoke;
    width: 100%;
    background-color: rgb(19, 35, 47);
    border-radius: 15px;
}

.imgContainer{
    display:flex;
    flex-direction: column;
    margin-left: 5%;
    width:15%;
    bottom: 15px;
}

.textContainer{
    display:flex;
    flex-direction: column;
    margin-left: 3%;
    padding-top: 3%;
    width:85%;
}

.quote{
    font-style: italic;
    text-align: left;
    font-size: 1vw;
    margin-bottom : 0px;
}

.author {
    text-align: left;
    font-size: 0.8vw;
    margin-top: 0px;
    margin-left: 5%;
}

.img {
    width: 100%;
    height: auto;
    padding-bottom: 20%;
}