.modal{
    margin-top: 15%;
}

.h2 {
    font-weight: bold;
    margin-top: 15px;
}

.message{
    text-align: center ;
}

.title {
    font-weight:500;
    margin-top: 15px;
}

.question {
    font-weight: 400 ;
    margin-top: 15px ;
}

.buttonsDiv {
    margin: auto;
    margin-bottom: 15px; 
    margin-top: 15px;
}

.noBtn {
    margin-right: 15px;
    border-radius: 30px;
    width: 150px;
    height: 50px;
    background-color: white;
    color: #208775;
    font-weight: bold;
    border: 2px solid #208775;
}

.yesBtn {
    margin-left: 15px;
    border-radius: 30px;
    width: 150px;
    height: 50px;
    background-color: #E66E6B;
    color: white;
    font-weight: bold;
    border: none;
}