.backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-image: url("../../assets/img/about-bg.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    opacity: 0.88;
}

.formContainer {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.stepBarContainer {
    position: relative;
    margin: auto;
    width: 100%;
    height: 10%;
    text-align: center;
    margin-top: 70px;
}

.stepContainer {
    position: relative;
    margin: auto;
    top: 40px;
    width: 65%;
    height: 60%;
    text-align: center;
    overflow-x: hidden;
    overflow-y: scroll;
}

.stepContainer2 {
    position: relative;
    margin: auto;
    top: 20px;
    width: 100%;
    height: auto;
    text-align: center;
}

.loader {
    color: #e2e4e5;
    position: relative;
    margin-bottom: 3%;
    float: right;
    margin-right: 7%;
}

@media screen and (max-width: 930px) {
    .stepContainer {
        position: relative;
        margin: auto;
        width: 100%;
        height: 50%;
        text-align: center;
    }
}


@media screen and (max-width: 1200px) {
    .stepContainer2{
        overflow:auto;
    }
}
@media screen and (max-width: 500px) {
    .stepContainer {
        height: 55%;
    }

    .stepContainer2 {
        height: 70%;
        overflow: auto;
    }

    .bottomContainer {
        position: absolute;
        margin: 5% 0;
        bottom: 0;
        width: 90%;
        height: 10%;
    }
}

.stepContainer::-webkit-scrollbar {
    width: 4px;
}

.stepContainer::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
}

.stepContainer::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
}

.stepContainer::-webkit-scrollbar-button {
    background-color: transparent;
    height: 10px;
}

.stepContainer2::-webkit-scrollbar {
    width: 4px;
}

.stepContainer2::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
}

.stepContainer2::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
}

.stepContainer2::-webkit-scrollbar-button {
    background-color: transparent;
    height: 10px;
}

/*formulaire> Buttons like "Save and continue"*/
.bottomContainer {
    position: relative;
    margin: auto;
    width: 90%;
    height: 10%;
    margin-top: 30px;
}

.button,
.button2,
.deleteButton {
    color: #e2e4e5;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 18px;
    align-items: center;
    border: none;
    float: right;
    margin-left: 12px;
}

.trashIcon {
    margin-right: 6px;
}

.button {
    background-color: #208775;
}

.button2 {
    background-color: #b84416;
}

.deleteButton {
    background-color: red;
}

.button:disabled,
.button2:disabled {
    opacity: 0.6;
}

.button:focus,
.button2:focus {
    outline: none;
}

@media screen and (max-width: 500px) {
    .button {
        background-color: #208775;
        color: #e2e4e5;
        border-radius: 10px;
        align-items: center;
        border: none;
        float: right;
    }

    .button:disabled {
        background-color: #208775;
        color: #e2e4e5;
        padding: 10px 25px;
        border-radius: 10px;
        font-size: 13px;
        align-items: center;
        border: none;
        float: right;
        opacity: 0.6;
    }

    .button2 {
        background-color: #b84416;
        color: #e2e4e5;
        border-radius: 10px;
        align-items: center;
        border: none;
        float: right;
    }

    .button2:disabled {
        background-color: #b84416;
        color: #e2e4e5;
        padding: 10px 25px;
        border-radius: 10px;
        font-size: 13px;
        align-items: center;
        border: none;
        float: right;
        opacity: 0.6;
    }
    .deleteButton {
        border-radius: 10px;
    }

    .deleteButton:disabled {
        opacity: 0.6;
    }

}
