.logo{
  width: 100px;
  background-color: rgba(0, 0, 0, 0.089);
  padding: 3px;
  border-radius: 10px;
}

.cancelButton {
  background-color: white !important;
  border-radius: 10px !important;
  color: #646665 !important;
  border-color: #646665 !important;
  font-size: 150% !important;
}
  
.cancelButton:hover {
  background-color: white !important;
  color: #646665 !important;
  border-color: #646665 !important;
  opacity: 0.7 ;
}
  
.confirmButton {
  width: 70% !important;
  border-radius: 10px !important;
  background-color: #208775 !important;
  border-color: #208775 !important;
  margin-right: 5% !important;
  font-size: 150% !important;
}
  
.confirmButton:hover {
  background-color: #1b7565 !important;
  border-color: #1b7565 !important;
}

.header {
  margin: auto;
  border: none;
}

.header button {
  color: white;
  font-size: 2rem;
  text-shadow: none !important;
  opacity: 0.8 !important;
}

.header button:hover {
  opacity: 0.5 !important;
}

.title {
  font-size: 20px;
}

.body{
  margin: auto;
}

.body div {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.footer {
  border: none !important;
}
.footer .subLink {
  cursor: pointer;
  color: #208775;
  font-weight: bold;
  font-size: large;
  margin-top: 2%;
  margin-right: 28%;
  text-decoration: underline;
}
.subLink:hover {
  cursor: pointer;
  color: #208775;
  font-weight: bold;
  font-size: large;
  margin-top: 2%;
  margin-right: 28%;
  text-decoration: underline;
  opacity: 0.7;
}
.subLink {
  color: #208775;
  font-weight: bold;
  font-size: large;
  margin-top: 2%;
  margin-right: 28%;
  text-decoration: underline;
}

.plusButton{
  font-size: x-large;
  padding: 10%;
  margin-left: 10%;
  background-color: white;
  border: none;
}
.minusButton{
  font-size: x-large;
  padding: 10%;
  margin-right: 10%;
  background-color: white;
  border: none;
}
