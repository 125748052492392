.previewContainer {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 450px;
    border-radius: 10px;
    background-color: rgba(0, 5, 22, 0.705);
    display: flex;
}

.previewContainer2 {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 450px;
    border-radius: 10px;
    background-color: rgba(0, 5, 22, 0.705);
    display: flex;
    pointer-events: none;
    filter: brightness(40%);
}

@media screen and (max-width: 500px) {
    .previewContainer,
    .previewContainer2 {
        height: 90%;
    }
}

.title {
    padding-left: 20px;
    color: aliceblue;
    font: Source Sans Pro;
    font-size: 25px;
    font-weight: 600;
    text-align: left;
    width: 50%;
}

.background {
    position: relative;
    z-index: 0;
    background-size: cover;
    width: 100%;
    background-position: center center;
    filter: brightness(70%);
    border-radius: 10px;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.user {
    position: relative;
    justify-content: left;
    top:1%;
    width: auto;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
    align-items: center;
}

.profilImage {
    height: 30px;
    width: 30px;
    border-radius: 30px;
}

.category {
    position: relative;
    background-color: #208775;
    margin: 5px;
    width: auto;
    height: 23px;
    padding-left: 2px;
    font: Source Sans Pro;
    font-size: 13px;
    border-radius: 30px;
    vertical-align: top;
}

.button {
    position: relative;
    border: none;
    background-color: transparent;
    color: white;
    vertical-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-around;
    justify-content: center;
}

.button:hover {
    outline: none;
}

.button:focus {
    outline: none;
}

.profileLink:hover {
    text-decoration: none;
}

.eventsDisplayContainer {
    position: relative;
    width: 100%;
    height: 60%;
    margin: 2%, 8%;
}

.headerPreview {
    position: relative;
    width: 100%;
    top:1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.poweredBy {
    position: relative;
    margin: auto;
    padding-right: 15px;
    width: 50%;
}

.textPoweredBy {
    position: relative;
    width: 136px;
    height: 23px;
    float: right;
    padding-top: 3px;
    background-color: aliceblue;
    font: Source Sans Pro;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    border-radius: 10px;
}

.lightning {
    background-color: aliceblue;
    color: rgb(224, 202, 0);
}

.eStory {
    color: #208775;
}

.eStory:hover {
    color: #1d7567;
    text-decoration: none;
}

.eStory:visited {
    color: #208775;
}

.eStory:before {
    content: " ";
}
