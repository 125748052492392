.signup {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loader {
    position: relative;
    left: 50%;
    margin-top: 7%;
    margin-bottom: 3%;
    color: white;
}

.label {
    position: absolute;
    text-align: left;
    top: -5px;
    width: 100%;
    right: 0.5%;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 600;
    font-size: x-large;
}

.label:after {
    color: #208775;
    content: " *";
}

.names {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 30px auto 30px;
}

.inputs2 {
    position: relative;
    width: 95%;
    height: 40px;
    background-color: transparent;
    border: 1px white solid;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 300;
    font-size: x-large;
    margin-right: 5%;
border-radius: 5px;
}

.label2 {
    position: absolute;
    text-align: left;
    right: 0.5%;
    top: -10%;
    width: 100%;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 600;
    font-size: x-large;
}

.inputs {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1px white solid;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 300;
    font-size: x-large;
    border-radius: 5px;
}

.field1 {
    position: relative;
    width: 49%;
}

.field2 {
    position: relative;
    width: 49%;
    margin: auto auto auto 5px;
}

.field3 {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.field4 {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    justify-content: right;
}

.inputs2:focus + .label,
.inputs2:valid + .label {
    font-size: large;
    transform: translate3d(-1.5%, -100%, 0);
    opacity: 1;
}

.inputs:focus + .label2,
.inputs:valid + .label2 {
    font-size: large;
    transform: translate3d(-1%, -100%, 0);
    opacity: 1;
}

.label2:after {
    color: #208775;
    content: " *";
}

.startedButton {
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 40px;
    border: 1px solid #208775;
    border-radius: 4px;
    width: 180px;
    float: right;
}

body {
    color: #666;
}

.error {
    top: 85%;
    font-size: 1rem;
    color: red;
}

@media screen and (max-width: 540px) {
    .names {
        flex-direction: column;
        align-content: inherit;
    }

    .inputs2 {
        position: relative;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px white solid;
        color: rgb(219, 219, 219);
        font: Source Sans Pro;
        font-weight: 300;
        font-size: x-large;
        margin-right: 5%;
    }

    .field1,
    .field2 {
        position: relative;
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
        justify-content: right;
        box-sizing: border-box;
    }

    .label,
    .label2 {
        font-size: larger;
    }

    .error {
        width: 40%;
    }
}

@media screen and (max-width: 440px) {
    .error {
        width: 40%;
    }
}

@media screen and (max-width: 392px) {
    .label2 {
        font-size: large;
    }

}
