.container {
    margin-top: 10%;
}

.congratulations > h2 {
    font-size: 1.5rem;
}

.access {
    width: 110%;
    font-size: 1.5rem;
}

.btn {
    font-size: 2rem;
}

.access:hover {
    background-color: #4d9f91;
    border: 1px solid #4d9f91;
    color: white;
}

.message {
    margin-bottom: 2%;
    font-size: large;
}

.image {
    margin-top: 5%;
    margin-bottom: 2%;
}
