.myCategories {
    position:relative;
    display: flex;
    padding: 6px;
    border: 3px solid #C3C9D1;
    box-sizing: border-box;
    width: 100%;
}

.categoryAdded {
    position: relative;
    width: 95%;
    display: flex;
    flex: auto;
    flex-wrap:wrap;
    align-items: center;
    align-content: space-between;
    overflow-y:scroll;
}

.categoryAdded::-webkit-scrollbar {
    display: none;
}

.categoryIcon {
    position:relative;
    width: 25px;
}

.categoriesToAdd {
    width:100%;
    height:auto;
    background-color: white;
    display: flex;
    flex: auto;
    flex-wrap:wrap;
    align-items: center;
    align-content: space-between;
    z-index: 1;
}

.rien {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    padding-top:5px;
    color: rgb(145, 145, 145);
}

.category {
    position: relative;
    background-color: #208775;
    margin: 5px;
    padding-left:5px;
    padding-right: 5px;
    height:29px;
    font-size: 16px;
    border-radius: 30px;
    vertical-align: top;
}

.space:after {
    content: " ";
    margin-right: 4px;
}

.button {
    position: relative;
    border : none;
    background-color: transparent;
    color: white;
    vertical-align: center;
    display:flex;
    flex-direction: row;
    align-items: center;
    align-content: space-around;
    justify-content: center;
}

.button:focus {
    outline:none;
}