.button {
    background-color: #208775 !important;
    border-color: #208775 !important;
}

.button:hover {
    background-color: #1b7565 !important;
    border-color: #1b7565 !important;
}

.title {
    font-size: 20px;
}