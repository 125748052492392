.modalHeader {
    color: whitesmoke;
    background-color: teal;
    height: 60px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
}

.modalHeader button {
    color:#FFFFFF!important;
    font-size: 2rem;
    text-shadow: none!important;
    opacity:0.8!important;
}
.modalHeader button:hover{
    opacity:0.5!important;
  }

.modalHeader button:focus {
    outline: none;
}

.searchButton {
    color: gainsboro;
}

.searchButton:focus {
    outline: none;
}

.modal {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
}

.viewImages {
    max-height: 400px;
    width: 100%;
    overflow-y: scroll;
}

.searched {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    margin-bottom: 20px;
}

.imageSearched {
    width: 50%;
    margin: auto;
}

.buttonSearched {
    width: 30%;
    margin: auto;
    height: 40px;
    font-size: 17px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    color: #f3f3f3;
    background-color: #4d9f91;
}

.buttonSearched:focus {
    outline: none;
}

@media (max-height: 634px) {
    .viewImages {
        max-height: 200px;
    }
}
