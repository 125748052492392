.eventCard {
    height: 13em;
    background-size: cover !important;
    background-position: center;
    position: relative;
    margin: 4px;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    transition: 300ms;
    cursor: pointer;
    overflow: "hidden";
}

.fiction{
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    border-radius: 10px;
    color : white;
    padding: 0px 15px 0px 15px;
    margin-bottom: 10px;
}

.cardType{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.timeline{
    background: #428576;
    border-radius: 10px;
    color : white;
    padding: 0px 15px 0px 15px;
    margin-bottom: 10px;
}

.eventCard:hover {
    box-shadow: 0 0 7pt 3pt #208775;
}

.reduced {
    height: 7em;
}

.reduced h3 {
    font-size: 18px;
}

.cardImg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.cardContent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    z-index: 0;
}

.cardText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cardText h3 {
    color: white;
    text-align: center;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
    transition: 0.2s;
}

.eventTitle {
    font-size: 25px;
    font-weight: 1000;
}

.eventDate {
    font-size: 1em;
}

.eventAuthor {
    font-size: 14px;
}

.cardLink {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    background: black;
    opacity: 0;
}

.eventLike {
    font-size: 30px;
}

.LikeIcon {
    font-size: 20px;
    margin-right: 6px;
}

.editIcon,
.loupe {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    margin-left: 5px;
}

.inProgress {
    width: 0;
    height: 0;
    border-top: 90px solid #208775;
    border-bottom: 90px solid transparent;
    border-right: 90px solid transparent;
    position: relative;
    z-index: 1;
}

.inProgress span {
    position: absolute;
    top: -90px;
    text-align: center;
    transform: rotate(-45deg);
    display: block;
    color: white;
    font-weight: 400;
}
