.searchBar input {
  background-color: #011222;
  box-sizing: border-box;
  flex-grow: 2;
  font-size: 18px;
}

.searchBar input[type='text'] {
  position: relative;
  color: #fff;
  padding: 10px 40px 10px 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  font-family: 'SourceSansPro';
  border-radius: 30px;
  border: 1px solid white;
  outline: none;
}

.searchIcon {
  position: relative;
  right: 40px;
  top: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
}

.searchForm {
  display: flex;
  flex-direction: row;
  height: 45px;
}
