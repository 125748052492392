$navBarHeight: 60px;

.navbar {
  display: flex;
  height: $navBarHeight;
  padding: 0 20px 0 30px;
  background-color: #011222;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.navBarLogo {
  background-color: #011222;
  display: block;
  padding-top: 10px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  height: 60px;
  margin-right: 25px;
}

.logo {
  display: block;
  margin-top: 0;
  cursor: pointer;
}

.navBarButton {
  background-color: #011222;
  display: block;
  padding-top: 17px;
  padding-left: 14px;
  padding-right: 14px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  height: 60px;
}
.navBarButtonHome {
  background-color: #208775;
  display: block;
  padding-top: 17px;
  padding-left: 14px;
  padding-right: 14px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  height: 60px;
}

.userProfile{
  color: white;
  font: Source Sans Pro;
  font-weight: 600;
  font-size: 1.2rem;
  bottom:10px;
  width: 500px;
  padding-bottom: 18px;
  padding-top:10px;
  align-self: right;
  position: relative;
  display: inline-block;
}

.wandlogo{
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-bottom: 2px;
}
a:hover .wandlogo{
  display: none;
}
.wandlogoGreen{
  display: none;
}
a:hover .wandlogoGreen {
  display: inline;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-bottom: 2px;
}
.searchBarContainer {
  padding-top: 7px;
  padding-left: 30px;
  width: 600px;
}

.navBarButton:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.selected {
  background-color: #208775;
}

.createButton{
  color: white;
  font: Source Sans Pro;
  font-weight: 600;
  width: 10%;
  padding-bottom: 18px;
  padding-top: 10px;
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.createButtonNavLink {
  height: 38px;
  width: 140px;
}

.createButton:focus, .createButton:active, .createButtonNavLink:hover, .createButtonNavLink:focus, .createButtonNavLink:active {
  text-decoration: none;
  outline: none;
  border: none;
}

.createButtonText {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
}

.createButtonTextShort {
  display:none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  right: 10%;
}

.spacer {
  flex-grow: 1;
}

.menu {
  background-color: #011222;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  height: 60px;
  display: none;
}

.menuButton {
  margin-top: 10px;
  font-size: 40px;
}

@media (max-width: 1820px) {
  .navbar {
    grid-template-columns: repeat(13, 1fr);
  }
}

@media (max-width: 1525px) {
  .navbar {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media (max-width: 1290px) {
  .navbar {
    grid-template-columns: repeat(8, 1fr);
  }

  .spacer {
    width: 30%;
  }
  
  .userProfile {
    width: 600px;
  }
}

@media (max-width: 1145px) {
  .navBarButton {
    display: none;
  }
  .createButton {
    display: none;
  }

  .menu {
    display: inline-block;
    grid-column-start: -2;
  }

  .searchBarContainer {
    width: 400px;
  }
}
@media (max-width: 767px) {
  .searchBarContainer {
    display: none;
  }
}

.navBarButtonSelected {
  background-color: #208775;
}

.AppMenu {
  position: absolute;
  top: 60px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(48, 48, 48, 0.959);
  z-index: 1;
  overflow-y: hidden;
}



.dropdownContent {
  display: none;
  font: Source Sans Pro;
  font-weight: 600;
  position: relative;
  color: black;
  background-color: #f9f9f9;
  float:right;
  width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px 10px 2px 10px;
  z-index: 1400;
  border-radius: 7px;
  text-align: center;
}

.dropdownContent2 {
  display: none;
  font: Source Sans Pro;
  font-weight: 600;
  position: relative;
  color: #000000;
  background-color: #FFFFFF;
  float:right;
  width: 100%;
  box-shadow: 0px 12px 12px rgba(0,0,0,0.25);
  margin-top: 5px;
  padding: 15px 15px 15px 15px;
  border-radius: 7px;
  z-index: 1400;
  text-align: center;
  width: 150%;
}
.dropdownContent2 a{
  color: black;
}

.dropdownContent2 a:hover{
  text-decoration: none;
  color: #208775;
}

.createButton:hover .dropdownContent2 {
  display: block;
}

.dropdownContent a{
  color: black;
}

.dropdownContent a:hover{
  text-decoration: none;
  color: #208775;
}

.userProfile:hover .dropdownContent {
  display: block;
}

.profilePicture{
  position:relative;
  margin-top: 7px;
  margin-left: 15px;
  margin-bottom: 7px;
  width: 35px;
  height: 35px;
}

.userName{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  text-align: end;
}
.usernameToken{
  position: relative;
  text-align: end;
}
.userName:hover, .profilePicture:hover{
  cursor: pointer;
}

.nbTokens{
  margin-top: -5px;
  font-weight: normal;
}


@media screen and (max-width: 1200px) {
  .userName {
    font-size: 0;
    width: 0;
  }
  .userProfile{
    position: absolute;
    float: none;
    right: 0;
    width: 35px;
    bottom:-20%;
  }

  .spacer {
    width: 45%;
  }

  .dropdownContent{
    position: fixed;
    top: 50px;
    right:65px;
  }

}
@media (max-width:1200px){
  .createButtonText{
    display: none;
  }
  .createButtonTextShort{
    display:inline-block;
  }
  .createButton {
    position: absolute;
    right: 2%;
    bottom:20%;
  }
}

@media (max-width:1200px){
  .userProfile{
    left: 85% !important;
    width: 50px;
  }
}

@media (max-width:1000px){
  .userProfile{
    left: 85% !important;
    width: 50px;
  }
  .spacer {
    width: 80%;
  }
}

@media (max-width:940px){
  .userProfile{
    left: 84% !important;
  }
}

@media (max-width:930px){
  .userProfile{
    left: 85% !important;
  }
}

@media (max-width:844px){
  .userProfile{
    left: 83% !important;
  }

  .spacer {
    width: 90%;
  }
}
@media (max-width:700px){
  .userProfile{
    left: 78% !important;
  }
}

@media (max-width:560px){
  .userProfile{
    left: 73% !important;
  }
}

@media (max-width:500px){
  .userProfile{
    left: 69% !important;
  }
}

@media (max-width:450px){
  .userProfile{
    left: 65% !important;
  }
}

@media (max-width:400px){
  .userProfile{
    left: 60% !important;
  }
}