.log > h2 {
    color: white;
    font-size: x-large;
    margin-bottom: 2%;
    text-align: left;
}

.backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-image: url("../../assets/img/about-bg.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: coveru u;
}

.log {
    color: white;
    margin: auto;
    position: relative;
    width: 80%;
    background-color: rgb(19, 35, 47, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
    padding: 30px;
    max-width: 600px;
    border-radius: 4px;
    box-shadow: 0 4px 10px 4px rgb(19 35 47 / 30%);
}

.input {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1px white solid;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 300;
    font-size: x-large;
    margin-bottom: 10px;
    margin-top: 20px;
}

.label {
    position: absolute;
    text-align: left;
    top: 21px;
    left: 12px;
    width: 100%;
    padding: 0px;
    transition: all 200ms;
    opacity: 0.5;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 600;
    font-size: 1.5rem;
}

.label:after {
    color: #208775;
    content: " *";
}

.input:focus + .label,
.input:valid + .label {
    font-size: large;
    transform: translate3d(-1.5%, -90%, 0);
    opacity: 1;
}

.input:focus + .label2,
.input:valid + .label2 {
    font-size: large;
    transform: translate3d(-1.5%, -90%, 0);
    opacity: 1;
}

.button > a,
.button > a:hover {
    color: white;
    text-decoration: none;
}

.button:hover {
    -webkit-filter: brightness(75%);
}

.field {
    width: 100%;
    position: relative;
}

.button {
    width: 20%;
    margin-top: 2%;
    float: right;
}

.error {
    position: absolute;
    top: 80%;
    font-size: 18px;
    color: red;
}

@media screen and (max-width: 700px) {
    .label {
        position: absolute;
        top: 21px;
        left: 12px;
    }
    .button {
        width: 30%;
    }
    .error {
        width: 50%;
        height: fit-content;
    }
}

@media screen and (max-width: 440px) {
    .label {
        position: absolute;
        top: 21px;
        left: 12px;
    }
    .button {
        width: 40%;
    }
    .error {
        width: 40%;
    }
}
