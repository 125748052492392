.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  top: 17px;
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-burger-bars-hover {
  background: #208675;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 60px;
}

.bm-menu {
  background: #011222;
  padding-top: 2.5em;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  font-family: 'SourceSansPro';
  font-weight: 1000;
  font-size: larger;
  text-decoration: none;
  color: white;
  height: 50px;
  padding-top: 7px;
}

.bm-item:hover {
  text-decoration: none;
  color: white;
}

.menu-item-active {
  background-color: #208775;
  width: 100%;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-search {
  display: none;
  line-height: 0;
}

@media (max-width: 767px) {
  .menu-search {
    display: block;
  }
}

.logged a{
  color: white;
}

.logged a:hover{
  text-decoration: none;
  color: white;
}

.tallMenu:after {
  content: " Timeline";
}
@media screen and (max-width : 385px) {
  .tallMenu:after {
    content: "";
  }
}

.tallMenuFiction:after {
  content: " Fiction";
}
@media screen and (max-width : 385px) {
  .tallMenuFiction:after {
    content: "Fiction";
  }
}