.imgMedia{
    height: 50%;
    width: 50%;
    margin: 0 10px 0;
}
.upBar {
    color: whitesmoke;
    background-color: teal;
    height: 60px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
  }
  .upBar button{
    color:#FFFFFF!important;
    font-size: 2rem;
    text-shadow: none;
    opacity:0.8!important;
  }
  .upBar button:hover{
   opacity: 0.5 !important;
  }
  
  .upBar button:focus{
    outline: none;
  }
  .modalButton {
    color:white !important;
    border-radius: 10px;
    background-color: #208675 !important;
    border: none;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    padding: 6px 10px;
  }
  .modalButtonAmazon {
    color:white !important;
    bottom: 15px;
    border-radius: 10px;
    background-color: #208675 !important;
    border: none;
    box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
    padding: 6px 10px;
  }

  .modalButtonAmazon:hover {
  text-decoration: none;
  outline: none;
  border: none;
  background-color: #1b7565 !important;
  }
  .modalButton:hover{
    text-decoration: none;
    outline: none;
    border: none;
    background-color: #1b7565 !important;
  }
  .mediaButton{
    margin-top:15px;
  }
  .mediaContainer{
display: flex;
  }
  .infoContainer{
      display:inline;
  }
