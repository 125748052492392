.blockForm {
    margin: auto;
    display: block;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.help {
    position: relative;
    height: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgb(219, 217, 217);
    text-align: center;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    position: relative;
    height: auto;
    color: #c3c9d1;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}
.table {
    position: relative;
    width: 100%;
    display:flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.colonne {
    position: relative;
    width: 80%;
}
.colonne2 {
    position: relative;
    width: 18%;
    margin-right: auto;
    justify-content: center;
}

.titleLabel {
    position: relative;
    height: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    text-align: left;
    padding-bottom: 10px;
    color: rgb(219, 217, 217);
}
.titleInput {
    position: relative;
    height: 53px;
    border: 3px solid #c3c9d1;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    color: white;
    padding-left: 10px;

}

.colonneIn {
    border: 3px solid #c3c9d1;
    position: relative;
    width: 80%;
}
.titleInputIn{
    position: relative;
    height: 53px;
    border: transparent;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    color: white;
    padding-left: 10px;
    animation: typing 1s steps(20, end);
    animation-fill-mode: both;
    white-space: nowrap;
    overflow: hidden;
}
@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}
.titleInputTypeText{
    position: relative;
    height: 53px;
    background-color: transparent;
    width: 100%;
    color: white;

}

.category {
    position: relative;
    color: #c3c9d1;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}
.privacy {
    position: relative;
    color: #c3c9d1;
    font-size: 18px;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}
.privacyTitle {
    font-weight: 700;
    line-height: 26px;
}
.switchDiv {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}
.switchButton {
    position: absolute;
    right: 25px;
}
.categorySelector {
    position: relative;
    background-color: transparent;
    font-size: 18px;
    width: 100%;
    color: rgb(219, 219, 219);
}
.image {
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}

.link {
    color: #208775;
}

.link:before {
    font-size: 22px;
    content: ' ';
    color: #208775;
}

.link:visited {
    color: #208775;
}

.link:hover {
    color: #2b9482;
    text-decoration: none;
    cursor: pointer;
}

.obligatoire:after {
    font-size: 22px;
    content: ' *';
    color: #208775;
}

span:before {
    content: ' ';
    margin: 4px;
}

.buttonGenerate {
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    color: white;
    border-radius: 10px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    border: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-weight: bold;
    cursor: pointer;
}
.buttonGenerate:disabled{
    opacity: 0.5;
    cursor: default;
}

.buttonGenerate:hover {
    background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%),
    linear-gradient(0deg, #98431B, #98431B);
    border-radius: 10px;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    border:none;
}

.buttonGenerate:focus { outline: none; }



.wandlogo{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.loader {
    color: #e2e4e5;
    position: relative;
    float: right;
    margin-right: 180%;
    margin-top: 25%;
}

@media screen and (max-width: 780px) {

    .colonne{
        width: 100%;
    }
    .colonneIn{
        width: 100%;
    }
    .colonne2{
        width: 100%;
        height: 100%;
    }
    .buttonGenerate{
        width: 60%;
        height:10%;
        margin-top: 10px;
    }
}