.searchDropDown {
  position: absolute;
  width: 480px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  z-index: 289;
  border-spacing: 0px;
}

@media (max-width: 1145px) {
  .searchDropDown {
    width: 380px;
  }
}

@media (max-width: 767px) {
  .searchDropDown {
    width: 92%;
  }
}

.listElem {
  padding-left: 10px;
}

.searchedWord {
  color: black;
  font-weight: 800;
  font-size: 20px;
  width: 100%;
  transition: 300ms;
  text-align: start;
  cursor: pointer;
  padding: 10px 15px;
}

.searchedWord:hover {
  background-color: #208675;
  color: white;
  text-decoration: none;
}

.searchTitle {
  background-color: #eeeeee;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  color: #777777;
  font-weight: 800;
  font-size: 20px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.searchResultContainer {
  max-height: 100px;
  width: 100%;
}

.searchResult {
  display: flex;
  padding: 10px;
  border-top: 1px solid #dddddd;
  width: 100%;
  transition: 300ms;
}

.searchResultContainer:hover {
  background-color: #208675;
  cursor: pointer;
  text-decoration: none;
}

.searchResult:hover .searchResultText {
  color: white;
  font-weight: bold;
}

.searchResultImage {
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.searchResultUserImage {
  border-radius: 50%;
  width: 50px;
}

.searchResultTimelineImage {
  border-radius: 10%;
  width: 60px;
}

.searchResultTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.searchResultText {
  color: #302d33;
  font-weight: 200;
  font-size: 15px;
  padding-left: 10px;
  transition: 300ms;
  line-height: normal;
}
