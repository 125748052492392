.container {
  width: 100%;
  height: 100%;
  margin: auto;
}

.box{
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 9%;
  min-height: 15rem;
  width: 60%;
}

.box:nth-child(even) {
  align-self: flex-end;
  margin-left: 40.5%;
  padding-right: 0;
}

.content {
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  width: 100%;
  max-width: 55%;
  text-align: right;
}


.box:nth-child(even) .content {
  text-align: left;
  align-items: flex-start;
}

.box:nth-child(even) .content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0,0,0,0.2);

}

.boxtimeline {
  position: relative;
  overflow: auto;
  background-color: white;
  width: 100%;
  z-index: 1;
  padding: 15px;
}

.boxtimelineTitle {
  font-size: 25px;
  text-align: end;
}

.boxtimelineDescription {
  font-size: 17px;
  text-align: justify;
}

.pImg {
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
  display: flex;
  position: relative;
}

.pImg3 {
  min-height : 5rem;
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
  display: flex;
  position: relative;
  background-color:#32c7ae;
}

.pImg2 {
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  z-index: 2;
  background-color: white;
}


@media screen and (max-width: 780px) {
  .boxtimeline {
    position: relative;
    width: 100%;
    padding: 15px;
    height: 50%;
  }

  .boxMobile{
    display: flex;
    padding-right: 30px;
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15%;
  }

  .contentMobile{
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    border-radius: 5px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    width: 100%;
    max-width: 80%;
    text-align: right;
  }

  .contentMobile::after{
    content: ' ';
    background-color: #fff;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 97%;
    top: calc(50% + 1.5%);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
  }

  .pImg {
    width: 100%;
    height: 50%;
  }

  .pImg3 {
    width: 100%;
    height: 50%;
  }

  .pImg2 {
    width: 100%;
  }

  .boxtimelineTitle {
    font-size: 16px;
    text-align: end;
  }

  .boxtimelineDescription {
    font-size: 11px;
    text-align: left;
  }

  .circleMobile{
    background-color: #208775;
    border: 3px solid #208775;
    font-size: 17px;
    color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: calc(50% + 1%);
    left: calc(1% - 16.75%);
    width: 20px;
    height: 20px;
  }


}

.generatedDate {
  color: #FFFFFF;
  font-size: 17px;
  top: auto;
  left: auto;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  background-color: #208775;
  z-index: 1;
}


.box::before{
  background-color: #208775;
  border: 3px solid #208775;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 50%;
  right: 16.60%;
  width: 20px;
  height: 20px;
  z-index: 100;
  transform: translate(50%, -50%);
}


.box:nth-child(even)::before {
  transform: translate(-50%, -50%);
  left: 15.90%;
}
