.noBlur {
  opacity: 1 !important;
 }

.barAI{
  position: fixed;
  top: 25%;
}

.blur {
  opacity: 0.33;
  pointer-events: none;
}

.buttonEditAiContainer{
  position: relative;
  margin-left: 85%;
  bottom: -3%;
  margin-bottom: 0;
}

.wandlogo{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.buttonEditAi{
  background: linear-gradient(264.81deg, #5823E2 -11.2%, rgba(88, 35, 226, 0) 125.09%), linear-gradient(0deg, #98431B, #98431B);
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  color: white;
  padding: 10px;
  margin-left: 12px;
}

.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-image: url("../../assets/img/about-bg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  opacity: 0.88;
}

.formContainer {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.stepBarContainer {
  position: relative;
  margin: 1% auto 5% auto;
  width: 100%;
  text-align: center;
  top: 60px;
}

.stepContainer {
  position: relative;
  margin: auto;
  margin-top: 8%;
  top: 0px;
  width: 65%;
  height: 60%;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

.stepContainer2 {
  position: relative;
  margin: auto;
  top: 4%;
  width: 100%;
  height: 80%;
  text-align: center;
  overflow: scroll;
}

@media screen and (max-width: 930px) {
  .stepContainer {
    position: relative;
    margin: auto;
    width: 100%;
    height: 50%;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .stepContainer {
    height: 50%;
  }

  .stepContainer2 {
    height: 55%;
  }

  .bottomContainer {
    position: absolute;
    margin: 5% 0;
    bottom: 0;
    width: 90%;
    height: 10%;
  }
}

.stepContainer::-webkit-scrollbar {
  width: 4px;
}

.stepContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.stepContainer::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}

.stepContainer::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.stepContainer2::-webkit-scrollbar {
  width: 4px;
}

.stepContainer2::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.stepContainer2::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}

.stepContainer2::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.bottomContainer {
  position: relative;
  margin: 1% auto;
  width: 90%;
  height: 7%;
  display: flex;
  left: 60%;
}

.button,
.button2,
.deleteButton {
  color: #e2e4e5;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 18px;
  align-items: center;
  border: none;
  float: right;
  margin-left: 12px;
}

.loader {
  color: #e2e4e5;
  position: relative;
  margin-bottom: 3%;
  float: right;
  margin-right: 7%;
}

.trashIcon {
  margin-right: 6px;
}

.button {
  background-color: #208775;
}

.button:hover {
  background-color: #196e60;
}

.button2 {
  background-color: #b84416;
}

.button2:hover {
  background-color: #a63b13;
}

.deleteButton {
  background-color: red;
}

.deleteButton:hover {
  background-color: #a63b13;
}

.button:disabled,
.button2:disabled {
  opacity: 0.6;
}

.button:focus,
.button2:focus {
  outline: none;
}

@media screen and (max-width: 500px) {
  .button {
    background-color: #208775;
    color: #e2e4e5;
    padding: 10px 25px;
    border-radius: 10px;
    font-size: 13px;
    align-items: center;
    border: none;
    float: right;
  }

  .button:disabled {
    background-color: #208775;
    color: #e2e4e5;
    padding: 10px 25px;
    border-radius: 10px;
    font-size: 13px;
    align-items: center;
    border: none;
    float: right;
    opacity: 0.6;
  }

  .button2 {
    background-color: #b84416;
    color: #e2e4e5;
    padding: 10px 25px;
    border-radius: 10px;
    font-size: 13px;
    align-items: center;
    border: none;
    float: right;
  }

  .button2:disabled {
    background-color: #b84416;
    color: #e2e4e5;
    padding: 10px 25px;
    border-radius: 10px;
    font-size: 13px;
    align-items: center;
    border: none;
    float: right;
    opacity: 0.6;
  }
}
