.header{
    margin-top: 5%;
    margin-right: auto;
    margin-left: auto;
}

.textBold{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #041221;
}


.textTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    color: #000000;
}
.button{
    width: 279px;
    height: 50px;
    color: white;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
}
.progressBar{
    align-items: center;
    justify-content: center;
    margin: auto auto 40px;
    text-align: center;
    width: 30%;
    height: 15px;
    background: #D9D9D9;
    border-radius: 25px;
}
.progressBar .progression{
    width: 60%;
    height: 15px;
    margin : 0px;
    background: #428576;
    border-radius: 25px;
}


.blockCheck{
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    text-align: left;
    margin-right: auto;
    margin-top: 5%;
    margin-left: 30%;
}
.header .checkRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
}



.customradiobtn{
    width:25px;
    height: 25px;
    border: 2px solid #428576;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
}

.customradiobtn .checkmark{
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: #428576;
    border-radius: 50%;
    display: inline-block;
    display : none
}
.customradiobtn input{
    display: none;
}

.customradiobtn input:checked + .checkmark{
    display: inline-block;
}

@media (max-width: 780px) {
    .textTitle{
        font-size:33px
    }
    .textBold {
        font-size: 30px;
    }
    .blockCheck{
        margin-left: 7%;
    }
    .header{
        margin-top: 20%;
    }
}