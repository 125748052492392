.stepBar {
    position:relative;
    width: 100%;
    border:10px transparent;
    height:40px;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    vertical-align: middle;
}

.ligne {
    position: absolute;
    width: 100%;
    z-index:0;
}

.bar {
    width: 100%;
    height:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index:1;
}

.horizontalLine {
    width:100%;
    height:2px;
    background-color: white;
}

.horizontalLineGreen {
    width:100%;
    height:2px;
    background-color: #208775;
}

.circleStep {
    position:relative;
    width:35px;
    height:35px;
    background-color:#C3C9D1;
    border-radius: 30px;
    align-items: center;
    padding:6px;
}

.circleStep p {
    position: relative;
    color:black;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.circleStep2 {
    position:relative;
    width:35px;
    height:35px;
    background-color:#208775;
    border-radius: 30px;
    align-items: center;
    padding:6px;
}

.circleStep2 p {
    position: relative;
    font-weight: 400;
    color:white;
    font-size: 16px;
    line-height: 21px;
}

.titles {
    position: relative;
    top: 10px;
    color: white;
    width: 100%;
    text-align: center;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 510px) {
    .titles {
        position: relative;
        top: 10px;
        color: white;
        font-size:14px;
        width: 100%;
        text-align: center;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}

.titleDiv {
    width: 25.5%;
}

