.buttons > a {
    background-color: #208775;
    color: #fff;
    font-weight: 600;
    padding: 14px 40px;
    border: 1px solid #208775;
    border-radius: 4px;
    margin-right: 2%;
    margin-top: 2%;
}

.serviceInfo {
    margin-top: 5%;
}

.serviceInfo > h3 {
    font-size: 1.5rem;
}

.image {
    margin-top: 5%;
}
.container h2 {
    font-size: 1.5rem;
    margin-top: 5%;
}
