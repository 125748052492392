.backgroundImage {
  position: fixed;
  width: 85%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #192837;
  filter: brightness(50%);
  z-index: -1;
}

.productTour {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000073;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.productTour2 {
  margin-top: 20%;
}

.productTour3 {
  margin-top: 65%;
}

.likeButtonIcon {
  border: solid #515b65;
  border-radius: 15px;
  background-color: #515b65;
  font-size: 30px;
  padding: 5px;
  // Transition
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
  cursor: pointer;
}

.likeButtonIcon2 {
  border: solid #208675;
  border-radius: 15px;
  background-color: #208675;
  font-size: 30px;
  padding: 5px;
  // Transition
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
  cursor: pointer;
}

.likeButtonIcon:hover {
  border: solid #208675;
  background-color: #208675;
}

.likeButtonText {
  margin-left: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.author {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;
}

.profileLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;
  font-weight: bold;
  color: white;
  text-align: start;
  padding-left: 10px;
}

.profileLink:hover {
  text-decoration: none;
  outline: none;
}

.authorText {
  position: relative;
  border: 1px solid #208675;
  background-color: #208675;
  border-radius: 20px;
  padding: 2px 10px;
  color: white;
}

.creationDateText {
  position: relative;
  color: white;
  margin-left: 0.5rem;
  font-style: italic;
}

.authorImage {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  margin-right: 5px;
}

.userList {
  display: none;
  width: auto;
  height: auto;
}

.namesList {
  text-align: center;
  color: white;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  width: auto;
  white-space: nowrap;
}

.likeButtonText:hover .userList {
  position: absolute;
  height: auto;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.548);
  width: auto;
  z-index: 6;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: inline-block;
}

.userList::-webkit-scrollbar {
  width: 4px;
}

.userList::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.userList::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}

.userList::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.recommendedTitle{
  font-size: 20px;
}

.mediaRecommended {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.modifyButton {
  position: relative;
  margin-left: auto;
  margin-right: 20px;
  height: auto;
  width: auto;
  padding: 5px;
  font: Source Sans Pro;
  font-size: 15px;
  font-weight: 500;
  background-color: #98431b;
  color: aliceblue;
  border: none;
  border-radius: 5px;
}

.modifyButton:focus,
.modifyButton:hover {
  outline: none;
  text-decoration: none;
  background-color: #c97148;
}

.timelineTitle {
  position: relative;
  font-size: 22px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding-left: 30px;
}

.likeButton {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.titleTimeline {
  color: white;
  margin-top: 5px;
  font-size: 16px;
}

@media screen and (max-width: 780px) {
  .container {
    position: inherit;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
  }
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: row;
    z-index: 0;
  }

  .backgroundImage {
    width: 100%;
  }



  .timelineTitle {
    position: relative;
    font-size: 22px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding-left: 30px;
  }

  .eventsContainers{
    position: relative;
    height: 80%;
    width: 100%;
    z-index: 1;
  }

  .eventsContainer{
    position: relative;
    height: 70%;
    width: 100%;
    z-index: 1;
    margin-right: auto;
    margin-left: auto;

  }

  .relatedRecommended2 {
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    overflow-x: none;
    margin-top: 45px;
  }

  .angleDown {
    height: 30px;
    width: 30px;
    margin-top: 20%;
    cursor: pointer;
  }

  .relatedRecommended {
    height: auto;
    width: 100%;
    margin-bottom: 15px;
    overflow-y: none;
    overflow-x: none;
  }


  .mediaRecommended {
    max-width: 600px;
  }

  .iconbackground {
    background-color: white;
    border-radius: 22px;
    height: 45px;
    width: 45px;
    margin-top: 17px;
    margin-left: auto;
    margin-right: auto;
  }

  .relatedRecommended::-webkit-scrollbar {
    width: 4px;
  }

  .relatedRecommended::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
  }

  .relatedRecommended::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
  }

  .relatedRecommended::-webkit-scrollbar-button {
    background-color: transparent;
    height: 10px;
  }



  .popupStyle {
    display: flex;
  }

  .timeline {
    position: fixed;
    height: 90%;
    width: 100%;
    padding-top: 10px;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
  }


  .title {
    position: relative;
    font-weight: bold;
    color: white;
    text-align: start;
    margin-right: 90px;
  }



  .eventsContainer2 {
    position: relative;
    height: 80%;
    width: 100%;
    z-index: 1;
  }

  .verticalTimelineMobile{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
  }

  .verticalTimelineMobile::after{
    background-color: #208775;
    content: '';
    position: absolute;
    left: calc(50% - 45%);
    width: 4px;
    height: 100%;
  }
}

.timelinePC {
  position: relative;
  width: 85%;
  padding-top: 10px;
}

.timelineVerticalPC{
  position: relative;
  width: 85%;
  padding-top: 10px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
}

.verticalTimelinePC{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}

.verticalTimelinePC::after{
  background-color: #208775;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.relatedRecommendedPC {
  position: relative;
  width: 20%;
  background-color: #192837;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 60px);
  background-size: cover;
}

.relatedRecommendedPC::-webkit-scrollbar {
  width: 4px;
}

.relatedRecommendedPC::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.relatedRecommendedPC::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}

.relatedRecommendedPC::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.titlePC {
  position: relative;
  font-weight: bold;
  color: white;
  text-align: start;
}

.likeButtonPC {
  display: flex;
  align-items: center;
}

.authorPC {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;
}

.eventsContainerPC {
  position: relative;
  height: 80%;
  width: 100%;
}
.eventsContainersPC {
  position: relative;
  width: 100%;
  height: 80%;

}

.pagePC {
  display: flex;
  position: absolute;
  height: 90%;
  width: 100%;
}

.productTourImageDesktop {
  margin-top: 20px;
}

.productTourTextDesktop {
  margin-top: 20px;
}

.setVerticalButtonSelected {
  position: relative;
  background-color: #428576;
  color: white;
  font-size: 15px;
  width: 90px;
  height: 30px;
  border: none;
  font-weight: 700;
}

.setVerticalButton {
  position: relative;
  background-color: #c4c4c4;
  border: none;
  color: #121520;
  font-size: 15px;
  width: 90px;
  height: 30px;
  font-weight: 700;
}

.setHorizontalButton {
  position: relative;
  background-color: #c4c4c4;
  color: #121520;
  font-size:15px;
  width: 100px;
  height: 30px;
  border: none;
  font-weight: 700;
}

.setVerticalButton:hover {
  outline: none;
  text-decoration: none;
  background-color: #428576;
  color: #FFFFFF;
}

.setHorizontalButton:hover {
  outline: none;
  text-decoration: none;
  background-color: #428576;
  color: #FFFFFF;
}

.horizontalVerticalButton{
  width: 190px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;


  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
  cursor: pointer;
}

.setHorizontalButtonSelected {
  position: relative;
  background-color: #428576;
  font-weight: 700;
  color: #FFFFFF;
  font-size: 15px;
  border: none;
  width: 100px;
  height: 30px;
}


