.imgLoading {
  width: 60px;
  background-color: rgba(0, 0, 0, 0.089);
  padding: 3px;
  border-radius: 10px;
}

/* Pulse */
@-webkit-keyframes pulse {
  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes pulse {
  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
