.blockForm {
    margin: auto;
    width: 100%;
    height:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
}

.subtitles {
    text-align: left;
    font: Source Sans Pro;
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin-top: 20px;
}

.eventCard {
    position: relative;
    width: 400px;
    height: 100%;
    margin-right: 20px;
    display:flex;
    flex-direction: column;
    align-content: space-between;
    justify-content:left;
}

.preview {
    position: relative;
    width: 65%;
    height: 100%;
}

@media screen and (max-width: 510px) {


    .blockForm {
        margin-top:20px;
    }

    .eventCard {
        width: 90%;
        top:5px;
        margin:auto;
    }

    .preview {
        width: 90%;
        height: 100%;
    }
}
@media screen and (max-width: 330px) {


    .blockForm {
        margin-top:20px;
    }

    .eventCard {
        width: 90%;
        top:5px;
        margin:auto;
    }

    .preview {
        margin-top: 70px;
        width: 90%;
        height: 100%;
    }
}
@media screen and (max-width: 780px) {


    .blockForm {
        margin-top:20px;
    }

    .eventCard {
        width: 90%;
        top:5px;
        margin:auto;
        margin-bottom: 27%;
    }

    .preview {
        margin-top: 70px;
        width: 90%;
        height: 100%;
    }
}