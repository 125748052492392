.blockForm {
  margin: auto;
  display: block;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.help {
  position: relative;
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: rgb(219, 217, 217);
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.title {
  position: relative;
  height: auto;
  color: #c3c9d1;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.titleLabel {
  position: relative;
  height: 26px;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 10px;
  color: rgb(219, 217, 217);
}

.titleInput {
  position: relative;
  height: 53px;
  border: 3px solid #c3c9d1;
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  color: white;
  padding-left: 10px;
}

.category {
  position: relative;
  color: #c3c9d1;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}
.privacy {
  position: relative;
  color: #c3c9d1;
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}
.privacyTitle {
  font-weight: 700;
  line-height: 26px;
}
.switchDiv {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
.switchButton {
  position: absolute;
  right: 25px;
}
.categorySelector {
  position: relative;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
  color: rgb(219, 219, 219);
}
.image {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.link {
  color: #208775;
}

.link:before {
  font-size: 22px;
  content: ' ';
  color: #208775;
}

.link:visited {
  color: #208775;
}

.link:hover {
  color: #2b9482;
  text-decoration: none;
  cursor: pointer;
}

.obligatoire:after {
  font-size: 22px;
  content: ' *';
  color: #208775;
}

span:before {
  content: ' ';
  margin: 4px;
}
