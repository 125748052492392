.carouselText {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.carouselContainer {
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.CarouselLink:hover {
  text-decoration: none;
}

.carouselTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor:pointer;
}

.carouselTextType {
  height: 32px;
  width: 32px;
  margin: 0 10px 0;
}

.carouselImage {
  min-height: 100px;
  max-height: 150px;
  max-width: 85%;
  cursor: pointer;
}

.carouselButton {
  cursor: pointer;
}

.title {
  position: relative;
  font-weight: bold;
  color: white;
  text-align: start;
}

@media (max-width: 450px) {
  .timelineTitle {
    font-size: 1.4rem;
  }
}

.recommendedTitle {
  font-size: 20px;
}

@media screen and (max-width: 780px) {
  .carouselContainer {
    max-width: 600px;
  }
}
