.container {
    font: Source Sans Pro;
    text-align: left;
    margin-left: 2%;
}

.container h1 {
    font-weight: 500;
    line-height: 1.1;
    color: #333;
    font-size: 2.5rem;
}

.container p {
    color: #666;
    margin-bottom: 5%;
    margin-top: 1%;
    font-size: 1.2rem;
}

.container a {
    margin-left: 0.5%;
    margin-right: 0.5%;
    color: black;
}

.container a:hover {
    text-decoration: none;
    color: #208775;
}

.title {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
