.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  background-image: url("../../assets/img/about-bg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow: auto;
}

.brandIcon {
  margin-right: 6px;
}

button > img {
  height: 10%;
  width: 5%;
}

.log {
  margin: auto;
  position: relative;
  width: 60%;
  background-color: rgb(19, 35, 47, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
  max-width: 100%;
  border-radius: 1%;
  box-shadow: 0 4px 10px 4px rgb(19 35 47 / 30%);
  max-width: 600px;
}

.or {
  position: relative;
  width: 95%;
  color: #666;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 5%;
}

.buttonDiv {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 30px;
}

.setLoginButtonSelected {
  position: relative;
  background-color: #208775;
  width: 50%;
  height: 100%;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  font-size: large;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.setLoginButton {
  position: relative;
  background-color: grey;
  width: 50%;
  height: 100%;
  border: none;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 0.4;
  font-size: large;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.setRegisterButton {
  position: relative;
  background-color: grey;
  width: 50%;
  height: 100%;
  border: none;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 0.4;
  font-size: large;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.setRegisterButtonSelected {
  position: relative;
  background-color: #208775;
  width: 50%;
  height: 100%;
  color: white;
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  font-size: large;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.setLoginButton:hover {
  background-color: #208775;
}
.setRegisterButton:hover {
  background-color: #208775;
}

.verticalLine {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: white;
}

.signUpsignIn {
  position: relative;
  width: 100%;
  margin: auto;
}

.footer {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #666;
  text-align: justify;
  font-size: large;
  height: 100%;
}

.externalLogin {
  margin-top: 3%;
  border-radius: 1%;
  width: 100%;
  height: 100%;
}

.loginGoogle {
  background-color: #df4930;
  border: 1px solid #df4930;
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
}

.loginFacebook {
  background-color: #507cc0;
  border: 1px solid #507cc0;
  margin-top: 1%;
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
}

.link {
  color: white;
}

.link:hover {
  color: #208775;
}

.loginGoogle > img,
.loginFacebook > img {
  margin-right: 2%;
  width: 30px;
  height: 30px;
}

.loginGoogle:hover {
  background-color: #e56d59;
  border: 1px solid #e56d59;
}

.loginFacebook:hover {
  background-color: #7396cd;
  border: 1px solid #7396cd;
}

@media screen and (max-width: 625px) {
  .log {
    width: 100%;
  }
}
