.textBold{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #041221;
}
.textTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    color: #000000;
}

.textInput{
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
    color: #428576;
    width: 33%;
    height: 81px;
    border: 1px solid #818288;
    border-radius: 25px;
    padding: 1%;
}

.button{
    width: 279px;
    height: 50px;
    color: white;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
}
.error {
    top: 80%;
    font-size: 18px;
    color: red;
}

.header{
    margin-top: 5%;
}
.progressBar{
    align-items: center;
    justify-content: center;
    margin: auto auto 40px;
    text-align: center;
    width: 30%;
    height: 15px;
    background: #D9D9D9;
    border-radius: 25px;
}
.progressBar .progression{
    width: 60%;
    height: 15px;
    margin : 0px;
    background: #428576;
    border-radius: 25px;
}
@media (max-width: 780px) {
    .textTitle{
        font-size:33px
    }
    .textBold{
        font-size: 30px;
    }
    .textInput{
        font-size: 25px;
        width: 75%;
    }
    .header{
        margin-top: 20%;
    }
}