.modal{
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 40px;
}

.modalHeader{
    color: whitesmoke;
    background-color: teal;
    border-bottom: 1px solid #e5e5e5;
    vertical-align: middle;
    width:auto;
    height: 60px; /* hauteur du parent */
    line-height: 60px; /* hauteur de ligne (identique) */
    white-space: nowrap;
}

.modalTitle {
    font-size: 18px;
    height: 60px; 
    line-height: 60px;
    padding-top: 12px;
    padding-left: 6px;
    transform: translateY(-50%);
}

.modalHeader button{
    color:#FFFFFF!important;
    font-size: 20px;
    text-shadow: none!important;
    opacity:0.8!important;
}
.modalHeader button:hover{
    opacity:0.5!important;
  }
.modalHeader button:focus{
    outline: none;
}

.searchButton{
    color: gainsboro;
}

.searchButton:focus {
    outline: none;
}

.profileLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 6px;
    margin-top: 5px;
    font-weight: bold;
    text-align: start;
    color: #208675;
}
  
.profileLink:hover {
    text-decoration: none;
    outline: none;
    color: #208647;
}

.authorImage {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    margin-right: 5px;
}

.modalBody {
    height: auto;
    overflow-x:hidden;
    overflow-y: scroll;
    max-height: 250px;
}

.modalContent {
    font-size: 13px;
}
