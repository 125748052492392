.modal{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    border:none;
    width:55%;
}

.modalHeader{
    height: auto;
    padding: 8px;
    vertical-align: middle;
    border:none;
}

.modalTitle {
    color: black;
    font-size: 40px;
    width: 100%;
    text-align: center;
}

.modalContent {
    color: black;
    font-size: 20px;
    text-align: center;
}

.modalDiv {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content:space-between;
    width: 100%;
}

.modalButton {
    color: black;
    position: relative;
    height: 60px;
    width: 40%;
    min-width: 200px;
    font-size: 20px;
    font-weight: 500;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
    background-color: whitesmoke;
    border:2px black solid;
}

.modalButton:hover {
    outline: none;
    background-color: whitesmoke;
    color: black;
    border:2px #4e4e4e solid;

}

.modalButton2 {
    position: relative;
    height: 60px;
    width: 40%;
    min-width: 200px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
    background-color: #208775;
    border:none;
}

.modalButton2Button {
    margin:auto;
    font-size: 20px;
    font-weight: 500;
    border:none;
    height: 100%;
    width: 100%;
    color: whitesmoke;
    background-color: transparent;
}

.modalButton2:hover {
    color: white;
    background-color: #1e7e6e;
    border: none;
}

@media screen and (max-width:490px) {
    .modal {
        width: 80%;
    }

    .modalTitle {
        font-size: 30px;
    }

    .modalContent {
        font-size: 16px;
    }
}

@media screen and (max-width:322px) {
    .modal {
        width: 80%;
    }

    .modalTitle {
        font-size: 30px;
    }

    .modalContent {
        font-size: 16px;
    }

    .modalButton,
    .modalButton2 {
        min-width:150px;
        font-size: 15px;
    }
}