.login {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loader {
    position: relative;
    left: 50%;
    margin-top: 7%;
    margin-bottom: 3%;
    color: white;
}

.inputs {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1px white solid;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 300;
    font-size: x-large;
    border-radius: 5px;
}
.field1,
.field2 {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.label {
    position: absolute;
    text-align: left;
    right: 0.5%;
    top: -10%;
    width: 100%;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 600;
    font-size: x-large;
}

.label:after {
    color: #208775;
    content: " *";
}

.label2 {
    position: absolute;
    text-align: left;
    right: 0.5%;
    top: -10%;
    width: 100%;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: rgb(219, 219, 219);
    font: Source Sans Pro;
    font-weight: 600;
    font-size: x-large;
}

.label2:after {
    color: #208775;
    content: " *";
}

.inputs:focus + .label2,
.inputs:valid + .label2 {
    font-size: large;
    transform: translate3d(-1%, -100%, 0);
    opacity: 1;
}

.inputs:focus + .label,
.inputs:valid + .label {
    font-size: large;
    transform: translate3d(-1%, -100%, 0);
    opacity: 1;
}

.field4 > .forgot {
    float: right;
    margin-bottom: 30px;
    font-size: large;
}

p > a {
    color: white;
}

p a:hover {
    text-decoration: none;
    color: #208775;
}

.loginButton {
    position: relative;
    width: 26%;
    left: 50%;
    margin-top: 7%;
    margin-bottom: 3%;
}

@media screen and (max-width: 625px) {
    .loginButton {
        margin-top: 30px;
        width: fit-content;
    }
}
@media screen and (max-width:540px) {
    .label,
    .label2{
        font-size: larger;
    }
}