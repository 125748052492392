// change the theme
$theme-colors: (
  'primary': #0074d9,
  'danger': #ff4136,
  'success': #208775,
  'dark': #011222,
  'light': #11ffef00,
);

body {
  font-family: 'SourceSansPro', sans-serif !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
