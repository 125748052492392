.card {
    height: 13em;
    background-size: cover !important;
    background-position: center;
    position: relative;
    overflow: hidden;
    transition: 300ms;
    cursor: pointer;
    overflow: "hidden";
    border: 3px solid;
    border-radius: 10px;
}

.card:hover {
    box-shadow: 0 0 7pt 3pt ;
}

.cardImg {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.cardContent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.cardText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cardTitle {
    font-size: 25px;
    font-weight: 900;
}

.cardPlus {
    font-size: 30px;
}

.plusIcon {
    font-size: 40px;
    margin-top: 10px;
}
