@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-ExtraLight.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-Bold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-Black.ttf');
  font-weight: 1000;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-BlackItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('assets/fonts/Source\ Sans\ Pro/SourceSansPro-Italic.ttf');
  font-style: italic;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}
