.AppTimelineList {
    background-color: #011222;
    padding: 30px 20px 20px;
  }

.blurImg {
    width: 100%;
    object-fit: cover;
    object-position: 60% 90%;
    height: 15rem;
}

.userProfile {
    padding-right: 15px;
    align-self: center;
    position: relative;
    display: inline-block;

}

.loaderContainer {
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #011222;
}

.myCol {
    padding: 5px !important
}

.profilePicture,
.profilePictureLogged {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.formEdit {
    align-content: center;
}

.editButton {
    border: solid 1px white !important;
    color: white;
    border-radius: 5px;
    margin-bottom: 3%;
}
.editButton:hover {
    text-decoration: none;
    color: #333 !important;
}

.updateButton {
    border: solid 1px white !important;
    color: white;
    border-radius: 5px;
    margin-bottom: 3%;
}
.updateButton:hover {
    text-decoration: none;
    color: #333 !important;
}

.saveButton {
    border: solid 1px white !important;
    border-radius: 5px;
    margin-left: 1%;
    margin-bottom: 3%;
}

.saveButton:hover {
    background-color: #4d9f91;
    border: 1px solid #4d9f91;
}

.profileContent {
    font: Source Sans Pro;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    background-color: #011222;
    background-size: cover;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
}

.validation {
    color: grey;
    font-size: 1.1rem;
    margin-top: 3%;
    margin-bottom: 2%;
}

.userName {
    margin-top: 2%;
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
}

.description {
    font-size: 1.3rem;
    margin-bottom: 0%;
    padding-bottom:2%;
    padding-right: 0.3%;
    margin-right: 6%;
}

.noDescription {
    font-size: 1.2rem;
    font-style: italic;
    margin-right: 70px;
    margin-top: 10px;
}

.plan {
    font-size: 1.3rem;
    margin-bottom: 0%;
    padding-bottom:2%;
    padding-right: 0.3%;
    margin-right: 2%;
    margin-top: 0.5%;
}

.noPlan {
    font-size: 1.2rem;
    padding-bottom:15px;
    font-style: italic;
    margin-right: 30px;
    margin-top: 10px;
}

.profilePictureLogged:hover {
    filter: brightness(50%);
}

.modalHeader {
    background-color: #208775;
}

.modalHeader span {
    font-size: 2.3rem;
    color: white;
    font-weight: 200;
}

.uploadButton {
    background-color: #208775;
    width: 100%;
}

.modalTitle {
    text-align: center;
    color: white;
    padding-left: 25%;
}

.buttonDiv {
}

.edit {
    background: none;
    border: 2px solid #a0b3b0;
    border-radius: 0;
    resize: vertical;
    color: white;
    width: 80%;
    align-self: center;
    margin-left: 10%;
    margin-right: 10%;
    padding: 0.5%;
}

.editTitle {
    color: white;
}

.bannerProfile {
    background:#011222;
    color:white;
}

.card {
    width: 22.5%;
    height: 208px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 10px;
    border: none !important;
    cursor: pointer;
}
.createTimelineContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonsProfile{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

