.rowRole{
  align-items: center;
  justify-content: center;
  display:flex;
  margin : auto;
  flex-direction: row;
  position: relative;
  width: 50%;
  height: 20%;
}

.border{
  border: 1px solid #428576;
  flex-direction: column;
  padding: 5%;
}

.border:hover{
  background-color: rgba(66,133,118,0.51);
  cursor: pointer;
}

.header{
  margin-top: 5%;
}
.border img{
  width: 40%;
  height: 40%;
}

.textBold{
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #041221;
}

.textTitle{
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 63px;
  text-align: center;
  color: #000000;
}

.progressBar{
  align-items: center;
  justify-content: center;
  margin: auto auto 40px;
  text-align: center;
  width: 30%;
  height: 15px;
  background: #D9D9D9;
  border-radius: 25px;
}
.progressBar .progression{
  width: 30%;
  height: 15px;
  background: #428576;
  border-radius: 25px;
}

@media (max-width: 780px) {
  .textTitle{
    font-size:33px
  }
  .textBold{
    font-size: 30px;
  }
  .rowRole{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .border{
    margin-top: 10%;
  }
  .header{
    margin-top: 20%;
  }
}