.cancelButton {
  background-color: red !important;
  border-color: red !important;
}

.cancelButton:hover {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}

.confirmButton {
  background-color: #208775 !important;
  border-color: #208775 !important;
}

.confirmButton:hover {
  background-color: #1b7565 !important;
  border-color: #1b7565 !important;
}

.header {
  background-color: #1b7565;
  color: white;
}

.header button {
  color: #ffffff !important;
  font-size: 2rem;
  text-shadow: none !important;
  opacity: 0.8 !important;
}
.header button:hover {
  opacity: 0.5 !important;
}
.title {
  font-size: 20px;
}
