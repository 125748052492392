.page404 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    background-image: linear-gradient(rgba(50, 50, 50, 0.6), rgba(50, 50, 50, 0.4)),
        url("../../assets/img/about-bg.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    overflow: auto;
    padding: "100px";
}

.title {
    color: white;
    font-size: 40px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.text {
    color: white;
    margin-top: 30px;
}

.number {
    margin-right: 40px;
    float: left;
}

.button {
    margin-top: 30px;
    background: #208775;
    border: 0;
    font-size: 20px;
    border-radius: 5px;
    padding: 6px 10px 6px 10px;
    color: white;
}

.button:hover {
    background: #1a6c5a;
}

.line {
    width: 50px;
    height: 10px;
    background: rgba(0, 0, 0, 0.3);
    position: relative;
}
.line:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0.3);
    position: relative;
    left: -6px;
}
.line:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid rgba(0, 0, 0, 0);
    border-left: 6px solid rgba(0, 0, 0, 0.3);
    position: relative;
    float: right;
    left: 6px;
    top: -10px;
}

.hori {
    transform: rotate(0deg);
    animation: horiz 899ms linear infinite;
}
.vert {
    transform: rotate(-90deg);
    animation: verti 926ms linear infinite;
}
.hori.top {
    top: -23px;
}
.vert.left {
    left: -31px;
}
.vert.right {
    left: 31px;
    top: -10px;
}
.middle {
    top: 12px;
}
.bottom.left {
    top: 35px;
}
.hori.bottom {
    top: 47px;
}
.bottom.right {
    top: 25px;
}
@keyframes verti {
    0% {
        transform: rotate(-90deg);
        filter: none;
    }

    19% {
        transform: rotate(-90deg);
        filter: none;
    }
    20% {
        transform: rotate(0deg);
        filter: blur(5px);
    }
    21% {
        transform: rotate(-90deg);
        filter: none;
    }
    23% {
        transform: rotate(0deg);
        filter: blur(5px);
    }
    24% {
        transform: rotate(-90deg);
        filter: none;
    }

    90% {
        transform: rotate(-90deg);
        filter: none;
    }
    91% {
        transform: rotate(0deg);
        filter: blur(5px);
    }
    92% {
        transform: rotate(-90deg);
        filter: none;
    }
    100% {
        transform: rotate(-90deg);
        filter: none;
    }
}

@keyframes horiz {
    0% {
        transform: rotate(0deg);
        filter: none;
    }

    29% {
        transform: rotate(0deg);
        filter: none;
    }
    30% {
        transform: rotate(90deg);
        filter: invert(1);
    }
    31% {
        transform: rotate(0deg);
        filter: none;
    }
    33% {
        transform: rotate(90deg);
        filter: invert(1);
    }
    34% {
        transform: rotate(0deg);
        filter: none;
    }

    50% {
        transform: rotate(0deg);
        filter: none;
    }
    51% {
        transform: rotate(90deg);
        filter: invert(1);
    }
    52% {
        transform: rotate(0deg);
        filter: none;
    }

    70% {
        transform: rotate(0deg);
        filter: none;
    }
    71% {
        transform: rotate(-90deg);
        filter: invert(1);
    }
    72% {
        transform: rotate(0deg);
        filter: none;
    }
    100% {
        transform: rotate(0deg);
        filter: none;
    }
}

.dialog404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.wrapper404 {
    margin: 50px auto;
}

.active404 {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.5);
}
.vert.active404 {
    box-shadow: -10px 5px 20px 0 rgba(0, 0, 0, 0.5);
}
.active404:before {
    border-right: 6px solid rgba(255, 255, 255, 0.9);
}
.active404:after {
    border-left: 6px solid rgba(255, 255, 255, 0.9);
}
