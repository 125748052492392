.App {
  position: relative;
  min-height: 100vh;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

.App-header {
  position: relative;
}

.App-footer {
  bottom: 0px;
  text-align: center;
  width: 100%;
  height: 5rem;
  background-color: rgba(255, 255, 255);
  padding-top: 10px;
}

.App-Cards {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: auto;
  margin: 2rem;
  color: white;
  height: 200px;
  overflow: hidden;
}

.card {
  border-radius: 5px;
}

.App-link {
  color: rgb(112, 76, 182);
}

.Intro-Video {
  height: 420px;
  z-index: -1;
  overflow: hidden;
  min-width: 100%;
  height: 420px;
  position: absolute;
  top: 60px;
  filter: brightness(0.5);
}

#myVid {
  width: 110%;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  filter: blur(2px);
}

.Intro-Text-Container {
  height: 418px;
  padding-top: 150px;
  color: #fff;
}

.Intro-Text-Title {
  font-size: 75px;
  margin-bottom: 30px;
  font-family: 'SourceSansPro';
  font-weight: lighter;
  line-height: 1.1;
}

.Intro-Text-Desc {
  font-size: 25px;
  font-family: 'SourceSansPro';
}

@media (max-width: 750px) {
  #myVid {
    display: none;
  }
  .Intro-Video {
    background-image: url('assets/img/IntroVideo.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    filter: brightness(0.5);
  }

  .Intro-Text-Container {
    height: 420px;
    padding-top: 75px;
  }

  .Intro-Text-Title {
    font-size: 60px;
  }

  .Intro-Text-Desc {
    font-size: 20px;
  }
}
