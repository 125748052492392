.blockForm {
  position: relative;
  margin: auto;
  display: block;
  width: 80%;
  height: 50%;
  display: flexbox;
  flex-direction: column;
  justify-content: space-evenly;
}


.h3 {
  font-size: 26px;
}

.title {
  position: relative;
  height: auto;
  padding: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: rgb(219, 217, 217);
  text-align: center;
}

.boxes {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.image {
  width: 45%;
  height: auto;
}

.image2 {
  width: 40%;
  height: auto;
  margin-top: 9px;
}

.box {
  position: relative;
  border: 1px solid white;
  width: 210px;
  height: 210px;
  margin-bottom: 20px;
}

.h4 {
  font-size: 22px;
  font-weight: 400;
  color: white;
}
.button {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
}

.movieForm {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalHeader {
  color: whitesmoke;
  background-color: teal;
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  vertical-align: middle;
}


.modalHeader button {
  color:#FFFFFF!important;
  font-size: 2rem;
  text-shadow: none!important;
  opacity:0.8!important;
}
.modalHeader button:hover{
  opacity:0.5!important;
}
.modalHeader button:focus {
  outline: none;
}

.searchButton {
  color: gainsboro;
}

.searchButton:focus {
  outline: none;
}

.modal {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
}

.viewArticles {
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
}

.searched {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
  margin-bottom: 20px;
}

.imageAndTitle {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  text-decoration: none;
  outline: none;
}

.titleItem {
  font-size: 14px;
  font-weight: 400;
  color: black;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}

.sourceItem {
  font-size: 14px;
  font-weight: 400;
  color: rgb(54, 54, 54);
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}

.imageSearched {
  width: 100%;
  margin: auto;
}

.buttonSearched {
  width: 30%;
  margin: auto;
  height: 40px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  color: #f3f3f3;
  background-color: #4d9f91;
}

.buttonSearched:focus {
  outline: none;
}

.mediaChoice {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mediaTitle {
  position: relative;
  background-color: #4d9f91;
  border-radius: 5px;
  padding: 5px;
  color: black;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  text-decoration: none;
}

.mediaTitle:hover {
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: #79cabd;
}

.deleteMedia {
  position: absolute;
  border: none;
  background-color: black;
  color: white;
  z-index: 2;
  margin-top: -5px;
  margin-right: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: none;
}

.box2 {
  position: relative;
  border: none;
  width: 210px;
  height: auto;
  padding: 5px;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.box2::-webkit-scrollbar {
  width: 4px;
}

.box2::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.box2::-webkit-scrollbar-thumb {
  background-color: rgb(219, 217, 217);
  border-radius: 5px;
}

.box2::-webkit-scrollbar-button {
  background-color: transparent;
  height: 10px;
}

.loaderContainer {
  margin: 5% 0;
  bottom: 0;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 10px;
  width: 90%;
  height: 10%;
}

.loader {
  color: #a3a3a3;
  position: relative;
  margin-bottom: 3%;
  float: right;
}

@media (max-height: 634px) {
  .viewArticles {
    max-height: 200px;
  }
}
