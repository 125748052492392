.profile {
  display: grid;
  grid-template-rows: 2fr 1fr 1fr 1fr 1f;
}

.profilPicture {
  grid-row: 1;
}

.pictureRadius {
  border-radius: 50%;
}

.profilName {
  color: #208775;
  font-weight: bold;
  margin-top: 35px;
  font-size: 18px;
}

.profilJob {
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 10px;
}

.profilDescription {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.profileSocial {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
}

.profilLinkedIn {
  grid-column-start: 6;
}

.profilIcon {
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.profilMail {
  grid-column-start: 7;
  font-size: 20px;
}

.profilFontAwesome {
  font-size: 20px;
  color: #208779;
  transition: 300ms;
}

.profilFontAwesome:hover {
  color: #155a51;
}
